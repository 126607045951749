import { CSVReport }  from "../../../services/CSVReport";
import { useEffect, useState,useRef } from "react";
import { apiGetReport2 } from "../../../services/ApiServices";
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
ChartJS.register(...registerables);
// import { Chart as ChartJS } from 'chart.js/auto'
// import {
//     Chart as ChartJS 
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
//   } from 'chart.js';
// import { Chart} from 'react-chartjs-2';
// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
//   );


  export const options = {
    plugins: {
      title: {
        display: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

const labels = ['PreK','K','Grade1','Grade2','Grade3','Grade4','Grade5','Grade6','Grade7','Grade8'];

export const data = {
labels,
datasets: [
    {
        label: 'Beginner',
        borderColor: 'rgb(255, 99, 132)',
        data: [5,1,10,3,10,6,7,4,5,9]
    },
    {
        label: 'Hero',
        data: [5,1,10,3,10,6,7,4,5,7],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
        label: 'Super Hero',
        data: [2,1,15,10,8,4,6,12,5,4,7],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
],
};
const Report4: React.FC = (): JSX.Element => {
    const chartRef = useRef<ChartJS>(null);
    const [masjid, setMasjid] = useState("all");
    const [graphOptions, setGraphOptions] = useState({...data});
    const [graphData, setGraphData] = useState<Array<any>>([]);
    const [reportData, setReportData] = useState<Array<any>>([]);
    const [reportCol] = useState<Array<string>>(
        [
            'Masjid', 
            'TotalChildren', 
            'NonHeroes',
            'Heroes',
            'SuperHeroes',  
            'PreKTotalChildren',
            'PreKNonHeroes',
            'PreKHeroes',
            'PreKSuperHeroes',
            'KTotalChildren',
            'KNonHeroes',
            'KHeroes',
            'KSuperHeroes',
            '1TotalChildren',
            '1NonHeroes',
            '1Heroes',
            '1SuperHeroes',
            '2TotalChildren',
            '2NonHeroes',
            '2Heroes',
            '2SuperHeroes',
            '3TotalChildren',
            '3NonHeroes',
            '3Heroes',
            '3SuperHeroes',
            '4TotalChildren',
            '4NonHeroes',
            '4Heroes',
            '4SuperHeroes',
            '5TotalChildren',
            '5NonHeroes',
            '5Heroes',
            '5SuperHeroes',
            '6TotalChildren',
            '6NonHeroes',
            '6Heroes',
            '6SuperHeroes',
            '7TotalChildren',
            '7NonHeroes',
            '7Heroes',
            '7SuperHeroes',
            '8TotalChildren',
            '8NonHeroes',
            '8Heroes',
            '8SuperHeroes',
        ]);
    useEffect(() => {
        getReportData();
        setGraphOptions({...data})
    }, []);
    
    const getReportData = () => {
        let graph:Array<any> = []
        setReportData([]);  
        apiGetReport2().then((childrenResponse) => {
        if (childrenResponse.Success && childrenResponse.Data) {
            setReportData(childrenResponse.Data);
            [...childrenResponse.Data].map(item=>[...reportCol].map((a,index)=>{
                graph[index] = (graph[index]||0) + item[a]
            }))
            let reportTotal:Array<any> = [];
            if(childrenResponse.Data.length>1){
                [...childrenResponse.Data].map(item=>[...reportCol].map((a,index)=>{
                    reportTotal[index] = (reportTotal[index]||0) + item[a]
                }))
            }
            setGraphData(reportTotal)
            if(graph.length>0){
                let op : Array<any> = [[],[],[],[]]
                for (let i = 5; i < graph.length; i++) {
                    const element = graph[i];
                    op[i%4].push(element)
                }
                if(chartRef.current){
                    chartRef.current.data = {
                        labels,
                        datasets: [
                            {
                                label: 'Beginner',
                                borderColor: 'rgb(255, 99, 132)',
                                data: op[2]
                            },
                            {
                                label: 'Hero',
                                data: op[3],
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                            {
                                label: 'Super Hero',
                                data: op[0],
                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            },
                        ],
                    };
                    setTimeout(()=>{
                        chartRef.current?.update();
                    },500)
                }
            }
        } else {
            setReportData([]);
        }
      });
    }
    const masjidHandler = (masjidName: string) => {
        setMasjid(masjidName);
        let graph:Array<any> = [];
        [...reportData].filter(item=>masjidName=="all"?true:item.Masjid==masjidName).map(item=>[...reportCol].map((a,index)=>{
            graph[index] = (graph[index]||0) + item[a]
        }))
        if(graph.length>0){
            let op : Array<any> = [[],[],[],[]]
            for (let i = 5; i < graph.length; i++) {
                const element = graph[i];
                op[i%4].push(element)
            }
            if(chartRef.current){
                chartRef.current.data = {
                    labels,
                    datasets: [
                        {
                            label: 'Beginner',
                            borderColor: 'rgb(255, 99, 132)',
                            data: op[2]
                        },
                        {
                            label: 'Hero',
                            data: op[3],
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                            label: 'Super Hero',
                            data: op[0],
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                    ],
                };
                setTimeout(()=>{
                    chartRef.current?.update();
                },1000)
            }
        }
    }
    return (
        <header className="masthead text-center">
            {/* FAQ Section Heading*/}
            <h2 className="page-section-heading text-center text-uppercase mb-0">Seggregate by grade </h2>
            {/* Icon Divider*/}
            <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                <div className="divider-custom-line"></div>
            </div>
            {
                reportData.length>1 && 
                    <div className="container" style={{marginBottom:"20px"}}>
                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input onChange={()=>masjidHandler('all')} className="btn-check" type="radio" checked={masjid=="all"} name="masjidR" id="masjidR00" value="all" />
                                <label className="btn btn-outline-primary" htmlFor="masjidR00">All</label>
                            {
                                [...reportData].map((item,index)=>{
                                    return <><input onChange={()=>masjidHandler(item["Masjid"])} checked={masjid==item["Masjid"]} className="btn-check" type="radio" name="masjidR" id={"masjidR"+index} value={item["Masjid"]} />
                                    <label className="btn btn-outline-primary" htmlFor={"masjidR"+index}>{item["Masjid"]}</label></>
                                })
                            }
                        </div>
                    </div>
            } 
            <div className="container">
                {/* <Bar data={{datasets: [{data: [20, 10],}],labels: ['a', 'b']}}/> */}
                <Chart type="bar" options={options} data={graphOptions}  ref={chartRef}/>
                {/* {getGraphData()} */}
            </div>
            <div className="container" style={{fontSize:"10px", overflowX:"scroll"}}>

                <table className="table table-bordered border-primary">
                    <thead>
                        <tr>
                            <th className="col" colSpan={6}></th>
                            <th className="col" colSpan={4}>Pre-K</th>
                            <th className="col" colSpan={4}>K</th>
                            <th className="col" colSpan={4}>Grade 1</th>
                            <th className="col" colSpan={4}>Grade 2</th>
                            <th className="col" colSpan={4}>Grade 3</th>
                            <th className="col" colSpan={4}>Grade 4</th>
                            <th className="col" colSpan={4}>Grade 5</th>
                            <th className="col" colSpan={4}>Grade 6</th>
                            <th className="col" colSpan={4}>Grade 7</th>
                            <th className="col" colSpan={4}>Grade 8</th>
                        </tr>
                        <tr>
                            <th>#</th>  
                            <th>Masjid</th>  
                            <th>Children</th>
                            <th>Beginner</th>
                            <th>Heroes</th>  
                            <th>Super Heroes</th>
                            <th>Children</th>
                            <th>Beginner</th>
                            <th>Heroes</th>  
                            <th>Super Heroes</th>
                            <th>Children</th>
                            <th>Beginner</th>
                            <th>Heroes</th>  
                            <th>Super Heroes</th>
                            <th>Children</th>
                            <th>Beginner</th>
                            <th>Heroes</th>  
                            <th>Super Heroes</th>
                            <th>Children</th>
                            <th>Beginner</th>
                            <th>Heroes</th>  
                            <th>Super Heroes</th>
                            <th>Children</th>
                            <th>Beginner</th>
                            <th>Heroes</th>  
                            <th>Super Heroes</th>
                            <th>Children</th>
                            <th>Beginner</th>
                            <th>Heroes</th>  
                            <th>Super Heroes</th>
                            <th>Children</th>
                            <th>Beginner</th>
                            <th>Heroes</th>  
                            <th>Super Heroes</th>
                            <th>Children</th>
                            <th>Beginner</th>
                            <th>Heroes</th>  
                            <th>Super Heroes</th>
                            <th>Children</th>
                            <th>Beginner</th>
                            <th>Heroes</th>  
                            <th>Super Heroes</th>
                            <th>Children</th>
                            <th>Beginner</th>
                            <th>Heroes</th>  
                            <th>Super Heroes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            [...reportData].filter(item=>masjid=="all"?true:item.Masjid==masjid).map((item,index)=>{
                                return(
                                    <tr key={item.Masjid}>
                                        <td key={item.Masjid+'-'+index} scope="col">{index+1}</td>
                                        {
                                            reportCol.map(a=><td key={item.id+'-'+a} scope="col">{item[a]}</td>)
                                        }
                                    </tr>
                                )
                            })
                        }
                        {
                            graphData.length>0 && masjid=="all" &&
                            <tr>
                                <td scope="col" colSpan={2}>Total</td>
                                {
                                    reportCol.map((a,index)=>index!==0 && <td key={'total-'+a} scope="col">{graphData[index]}</td>)
                                }
                            </tr>   
                        }
                    </tbody>
                </table>
            </div>    
            <button type="button" className="btn btn-primary btn-lg" onClick={()=>CSVReport(reportData,"Seggregate by grade",true,reportCol)}>Download Report</button>
        </header>
    );
}

export default Report4;
