import { loggedInDefaultMasjid } from "../../../services/MasjidServices";
import { useTypedSelector } from "../../../store";

const Footer01: React.FC = (): JSX.Element => {

    const authUser = useTypedSelector(state => state.authUser);
    const rhConfig = useTypedSelector(state => state.rhConfig);
    const masjid = loggedInDefaultMasjid(authUser, rhConfig.Masaajid);

    return (
        <footer className="footer text-center">
            <div className="container">
                <div className="row">
                    {/* Footer Contact*/}
                    <div className="col-lg-6 mb-3 mb-lg-0">
                        <h4 className="text-uppercase mb-3">Contact Us</h4>
                        <p className="lead mb-0">
                            <a className="btn btn-outline-light" href="mailto:admin@youngramadanheroes.com?subject=Ramadan Hero Question"><i className="fas fa-envelope me-2"></i>Email</a>
                        </p>
                    </div>
                    {/* Footer Donate*/}
                    <div className="col-lg-6 mb-3 mb-lg-0">
                        <h4 className="text-uppercase mb-3">Support Us</h4>
                        <p className="lead mb-0">
                            <a className="btn btn-outline-light" href={masjid.donation_link} target="_new"><i className="fas fa-donate me-2"></i>Donate</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer01;