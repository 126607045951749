import { useEffect, useState, useRef } from "react";
import { apiGetParents } from "../../../services/ApiServices";

import { CSVReport }  from "../../../services/CSVReport";
const Header01: React.FC = (): JSX.Element => {
    const [masjid, setMasjid] = useState("all");
    const [reportData, setReportData] = useState<Array<any>>([]);
    const [reportCol] = useState<Array<string>>(
        [  
            'FirstName',
            'LastName',
            'Masjid',
            'Age',
            'Grade',
            'Gender',
        ]);
    useEffect(() => {
        getReportData();
    }, []);

    const getReportData = () => {
        setReportData([]);  
        let children:Array<any> = [];
        apiGetParents().then((childrenResponse) => {
        if (childrenResponse.Success && childrenResponse.Data) {
            [...childrenResponse.Data].map(item=>{
                [...item['Parents']].map(parent=>{
                    [...parent['Children']].map(child=>{
                        child['Masjid'] = item['Masjid']
                        child['ParentsEmail'] = parent['ParentsEmail']
                        children.push(child)
                    })
                })
            })
            children.sort((a,b)=>a.FirstName.charCodeAt(0)-b.FirstName.charCodeAt(0))
            setReportData(children);
        } else {
            setReportData([]);
        }
      });
    }
    const masjidHandler = (masjidName: string) => {
        setMasjid(masjidName);
    }
    const getMasjid = () =>{
        let data = [...reportData].map(item=>item.Masjid);
        let dist:Array<string> = []
        let items = new Set(data);
        items.forEach(function(item){
            dist.push(item);
        });
        return [...dist];
    }
    return (
        <header className="masthead text-center">
            {/* FAQ Section Heading*/}
            <h2 className="page-section-heading text-center text-uppercase mb-0">View individual Ibadah chart</h2>
            {/* Icon Divider*/}
            <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                <div className="divider-custom-line"></div>
            </div>
            {
                [...getMasjid()].length>1 && 
                    <div className="container" style={{marginBottom:"20px"}}>
                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input onChange={()=>masjidHandler('all')} className="btn-check" type="radio" checked={masjid=="all"} name="masjidRC" id="masjidRC00" value="all" />
                                <label className="btn btn-outline-primary" htmlFor="masjidRC00">All</label>
                            {
                                [...getMasjid()].map((item,index)=>{
                                    return <><input onChange={()=>masjidHandler(item)} checked={masjid==item} className="btn-check" type="radio" name="masjidRC" id={"masjidRC"+index} value={item} />
                                    <label className="btn btn-outline-primary" htmlFor={"masjidRC"+index}>{item}</label></>
                                })
                            }
                        </div>
                    </div>
            }
            
            <div className="container"  style={{fontSize:"12px"}}>

                <table className="table table-bordered border-primary">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>FirstName</th>
                            <th>LastName</th>  
                            <th>Masjid</th>  
                            <th>Age</th> 
                            <th>Grade</th> 
                            <th>Gender</th> 
                            <th>Chart</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reportData.filter(item=>masjid=="all"?true:item.Masjid==masjid).map((item,index)=>{
                                return(
                                    <tr key={item.ChildId}>
                                        <td key={item.Masjid+'-'+index} scope="col">{index+1}</td>
                                        {
                                            reportCol.map(a=><td key={item.id+'-'+a} scope="col">{item[a]}</td>)
                                        }
                                        <td key={item.ChildId+"link"} scope="col">
                                            <a  href={`/adminPrintChart?childId=${item.ChildId}&firstName=${item.FirstName}&lastName=${item.LastName}&email=${encodeURIComponent(item.ParentsEmail)}`} target="_blank">
                                                View Ibadah Chart
                                            </a>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>      
            <button type="button" className="btn btn-primary btn-lg" onClick={()=>CSVReport(reportData,"VIEW INDIVIDUAL IBADAH CHART",true,reportCol)}>Download Report</button>
        
        </header>
    );
}

export default Header01;
