import { useRef } from "react";
import { useTypedSelector } from "../../store";
import { TostAlert } from "../../store/TostAlertReducer";
import styles from "./Tost.module.scss";

interface Props {}

const Tost: React.FC<Props> = (): JSX.Element => {
    const container = useRef<HTMLDivElement>(null);
    const tostAlert = useTypedSelector(state => state.tostAlert);

    const tostAlertShowClassNames = (ta: TostAlert) => {
        if (ta.show) {
            return `${styles.container} ${styles.show}`;
        } else {
            return styles.container;
        }   
    }

    return (
        <div ref={container} className={tostAlertShowClassNames(tostAlert)}>
            <div className={styles.typeMessage}>
                <div className={styles[tostAlert.alert]}></div>
                <div className={styles.message}>
                    {tostAlert.message}
                </div>
            </div>
        </div>
    );
}

export default Tost;