import { isNotBlank } from "./CommonServices";
import { AuthUser, LocalStorageTypes } from "./Types";


export const lsSetAuthUser = (authUser?: AuthUser | null | undefined) => {
    if (!authUser || authUser == null) {
        localStorage.removeItem(LocalStorageTypes.authUser)
    } else {
        const authUserString = JSON.stringify(authUser)
        localStorage.setItem(LocalStorageTypes.authUser, authUserString);
    }
}

export const lsGetAuthUser = (): AuthUser | undefined => {
    const authUserString = localStorage.getItem(LocalStorageTypes.authUser);
    let authUserJson = undefined;
    if (isNotBlank(authUserString)) {
        // @ts-ignore
         authUserJson = JSON.parse(authUserString);
    }
    return authUserJson;
}
