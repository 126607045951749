import React, { useState } from "react";
import { apiRegister } from "../services/ApiServices";
import { sha512 } from "../services/HashUtils";
import { createSelectOptions } from "../services/JsxService";
import { DEFAULT_MASJID } from "../services/MasjidServices";
import { KeyValue, RegisterRequest, RegisterResponse } from "../services/Types";
import { useTypedSelector } from "../store";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";


const RegisterModel: React.FC = (): JSX.Element => {

  const rhConfig = useTypedSelector(state => state.rhConfig);

  const initialValues = { email: "", password: "", confirmpassword: "", firstname: "", lastname: "", phonenumber: "", masjid_id: DEFAULT_MASJID.id };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({ email: "", password: "", confirmpassword: "", firstname: "", lastname: "", phonenumber: "", agree: "", masjid_id: "" });

  const [isAgree, setIsAgree] = useState(false);

  const [registerResponse, setRegisterResponse] = useState<RegisterResponse>({
    Success: false,
    Message: "",
    Exception: false
  });

  //Collect form values
  const onChange = (e: any) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheckbox = () => {
    setIsAgree(!isAgree);
  }

  //called on submit of form which calls validate function
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validate(formValues)
    setFormErrors(errors);


    // API Call
    if (!errors.containsError) {
      const registerRequest: RegisterRequest = {
        email: formValues.email,
        first_name: formValues.firstname,
        last_name: formValues.lastname,
        password: formValues.password,
        passwordHash: sha512(formValues.password),
        phone_number: formValues.phonenumber,
        masjid_id: `${formValues.masjid_id}`
      }

      console.log("register request", registerRequest);
      const registerResponsePromise = apiRegister(registerRequest);
      registerResponsePromise.then(registerResponse => handleRegisterResponse(registerResponse));
    }
  }

  const handleRegisterResponse = (registerResponse: RegisterResponse) => {
    setRegisterResponse(registerResponse);
  }

  //validate the form responses
  const validate = (values: any) => {
    const errors = { containsError: false, email: "", password: "", confirmpassword: "", firstname: "", lastname: "", phonenumber: "", agree: "", masjid_id: "" };

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Email is required";
      errors.containsError = true;
    } else if (!regex.test(values.email)) {
      errors.containsError = true;
      errors.email = "This is not a valid email format!";
    }


    if (!values.password) {
      errors.password = "password is required";
      errors.containsError = true;
    } else if (values.password.length < 4) {
      errors.password = "Password must be more than 4 characters";
      errors.containsError = true;
    } else if (values.password.length > 10) {
      errors.password = "Password cannot exceed more than 10 characters";
      errors.containsError = true;
    }


    if (!values.confirmpassword) {
      errors.containsError = true;
      errors.confirmpassword = "password is required"
    } else if (values.confirmpassword.length < 4) {
      errors.containsError = true;
      errors.confirmpassword = "Password must be more than 4 characters";
    } else if (values.confirmpassword.length > 10) {
      errors.containsError = true;
      errors.confirmpassword = "Password cannot exceed more than 10 characters";
    } else if (!(values.password === values.confirmpassword)) {
      errors.containsError = true;
      errors.confirmpassword = "Passwords do not match";
    }


    if (!values.firstname) {
      errors.firstname = "Firstname is required"
      errors.containsError = true;
    }
    if (!values.lastname) {
      errors.lastname = "Last Name is required"
      errors.containsError = true;
    }
    if (!values.phonenumber) {
      errors.phonenumber = "Phone Number is required"
      errors.containsError = true;
    } else if (isNaN(values.phonenumber)) {
      errors.phonenumber = "Phone Number should be numbers only without hyphens"
      errors.containsError = true;
    }

    if (!isAgree) {
      errors.agree = "Terms and conditions not agreed"
      errors.containsError = true;
    }

    return errors;
  };


  const createMasjidSelect = () => {
    if (!rhConfig || !rhConfig.Masaajid || rhConfig.Masaajid.length < 1) {
      return;
    }

    const optionKeyValues = rhConfig.Masaajid
      .sort((m1, m2) => {
        if (m1.id > m2.id) return 1
        else if (m1.id < m2.id) return -1
        else return 0;
      })
      .map<KeyValue>(m => {
        return { key: `${m.id}`, value: `${m.name} - ${m.location}` }
      });

    return createSelectOptions(optionKeyValues, initialValues.masjid_id ? "" : `${initialValues.masjid_id}`);
  }

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  };


  const showForm = (registerResponse: RegisterResponse) => {
    if (registerResponse.Success) {
      return (
        <div>
          <div>Successfully Registered!</div>
          <div>Please check your email to activate your account. Please check your email Spam folder if you don't find it in your Inbox</div>
        </div>
      )
    } else {
      return (
        // <RegisterForm />
        // Remove Form from here

        <form onSubmit={onSubmit}>

          <div className="FormField">
            <div className="form-floating mb-3">

              <input className="form-control"
                name="email"
                type="email"
                placeholder="name@example.com"
                value={formValues.email}
                onChange={onChange}
              />
              <label htmlFor="email">Email address</label>
            </div>

            <p className="text-danger">{formErrors.email}</p>

            <div className="form-floating mb-3">
              <input className="form-control"
                name="password"
                type="password"
                placeholder="Password"
                value={formValues.password}
                onChange={onChange}
              />
              <label htmlFor="password">Password</label>
            </div>
            <p className="text-danger">{formErrors.password}</p>

            <div className="form-floating mb-3">
              <input className="form-control"
                name="confirmpassword"
                type="password"
                placeholder="Confirm Password"
                value={formValues.confirmpassword}
                onChange={onChange}
              />
              <label htmlFor="confirmpassword">Confirm Password</label>
            </div>
            <p className="text-danger">{formErrors.confirmpassword}</p>

            <div className="form-floating mb-3">
              <input className="form-control"
                name="firstname"
                type="text"
                placeholder="First Name"
                value={formValues.firstname}
                onChange={onChange}
              />
              <label htmlFor="firstname">Parent's First Name</label>
            </div>
            <p className="text-danger">{formErrors.firstname}</p>

            <div className="form-floating mb-3">
              <input className="form-control"
                name="lastname"
                type="text"
                placeholder="Last Name"
                value={formValues.lastname}
                onChange={onChange}
              />
              <label htmlFor="lastname">Parent's Last Name</label>
            </div>
            <pre className="text-danger">{formErrors.lastname}</pre>

            <div className="form-floating mb-3">
              <input className="form-control"
                name="phonenumber"
                type="numeric"
                placeholder="Phone Number"
                value={formValues.phonenumber}
                onChange={onChange}
              />
              <label htmlFor="phonenumber">Parent's Phone Number</label>
            </div>
            <p className="text-danger">{formErrors.phonenumber}</p>



            <div className="form-floating mb-3">
              <select id="masjid_id" onChange={onSelectChange} value={formValues.masjid_id} name="masjid_id" className="form-select" aria-label="Default select example">
                {createMasjidSelect()}
              </select>
              <label htmlFor="masjid_id">Masjid</label>
            </div>



            <div className="form-floating mb-3">
              <TermsAndConditions fullPage={false} height="200px" />
              <p>
                <input className="FormField__Checkbox" name="isAgree" type="checkbox" onChange={handleCheckbox} />
                <label htmlFor="FormField__CheckboxLabel" style={{ fontSize: "1.2rem", padding: "10px" }}>
                  I agree to above Terms &amp; conditions
                </label>

              </p>
            </div>
            <p className="text-danger">{formErrors.agree}</p>
            <p>&nbsp;</p>
            <div className="form-floating mb-3">
              <button className="btn btn-primary btn-xl mb-5">
                Register
              </button>
            </div>

          </div>
        </form>


      );
    }

  }




  return (

    <div className="portfolio-modal modal fade" id="registerModal" tabIndex={-1} aria-labelledby="registerModal" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
          <div className="modal-body text-center pb-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  {/* Register Modal - Title*/}
                  <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Register</h2>
                  {/* Icon Divider*/}
                  <div className="divider-custom">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                    <div className="divider-custom-line"></div>
                  </div>

                  {!registerResponse.Success && registerResponse.Message.length > 0
                    && <div style={{ color: "red" }}>
                      {registerResponse.Message}
                    </div>}
                  {showForm(registerResponse)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  );

}
export default RegisterModel;


