import { store } from ".";
import { defaultRhConfig } from "../services/DefaultObjects";
import { RhConfig } from "../services/Types";


const createInitialState = defaultRhConfig;


enum RhConfigAction {
    SAVE = "RH_CONFIG_SAVE"
}


export interface RhConfigActionType {
    type: RhConfigAction;
    payload: RhConfig;
}


export const saveRhConfig = (rhConfig: RhConfig) => {
    store.dispatch<RhConfigActionType>({
        type: RhConfigAction.SAVE,
        payload: rhConfig
    });
}


export function rhConfigReducer(prevState: RhConfig = createInitialState(), action: RhConfigActionType) {
    let payload: RhConfig;
    switch (action.type) {
        case RhConfigAction.SAVE:
            payload = action.payload;
            break;
        default:
            payload = prevState
    }
    return payload;
}
