import { loggedInDefaultMasjid } from "../services/MasjidServices";
import { useTypedSelector } from "../store";

const CustomMasjidBrand: React.FC = (): JSX.Element => {

    const authUser = useTypedSelector(state => state.authUser);
    const rhConfig = useTypedSelector(state => state.rhConfig);
    const masjid = loggedInDefaultMasjid(authUser, rhConfig.Masaajid);

    return (
        <section className="page-section mb-0">
            <div className="container">
                <div className="mb-4" style={{display:"flex", alignItems: "center", justifyContent:"center"}}>
                <img src={`static/assets/masjid_logo/masjid_${masjid.id}.png`} alt="" />
                </div>
                <h2 className="page-section-heading text-center mb-4">
                    <a href={masjid.donation_link} target="_blank">{masjid.name}</a>
                </h2>
                <div style={{textAlign: "center"}}>{masjid.address}</div>
                <div style={{textAlign: "center"}}>{masjid.contact}</div>
               
            </div>
        </section>
    );
}

export default CustomMasjidBrand;