import { CSVReport }  from "../../../services/CSVReport";
import CookieConsent from "../CookieConsent";
import Copyright from "../Copyright";
import Footer01 from "../Footer01/Footer01";
import Report from "./Report";
import Report4 from "./Report4";
import ChildrenReport from "./ChildrenReport";
import IbadahReport from "./IbadahReport";
import Navigation from "../Navigation/Navigation";
import { useState} from "react";
import { apiChildrenData } from "../../../services/ApiServices";

const Layout01: React.FC = ({ children }): JSX.Element => {
    const [reportView, setReportView] = useState('');
    const reportHandler = (report: string) => {
        setReportView(report)
    }
    const downloadReport = () =>{
        reportHandler('5');
        apiChildrenData().then((childrenResponse) => {
            console.log(childrenResponse)
            if (childrenResponse) {
                var fileName = "Children_Data";
                fileName += "_"+new Date().getTime()
                //Initialize file format you want csv or xls
                var uri = "data:text/csv;charset=utf-8," + escape(childrenResponse);
                var link = document.createElement("a");
                link.href = uri;
                link.download = fileName + ".csv";
                console.log(uri)
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    }
    return (
        <div>
            <Navigation/>
            <section className="masthead text-center container">
                <div className="row">
                    <div onClick={()=>reportHandler('1')} className={"col-xs-12 col-md-4 report_tile " + (reportView==='1' && 'selected')}>SUMMARY OF REGISTRATION</div>
                    <div onClick={()=>reportHandler('2')} className={"col-xs-12 col-md-4 report_tile " + (reportView==='2' && 'selected')}>SEGGREGATE BY GRADE</div>
                    <div onClick={()=>reportHandler('3')} className={"col-xs-12 col-md-4 report_tile " + (reportView==='3' && 'selected')}>VIEW INDIVIDUAL IBADAH CHART</div>
                    <div onClick={()=>reportHandler('4')} className={"col-xs-12 col-md-4 report_tile " + (reportView==='4' && 'selected')}>IBADAH SUMMARY</div>
                    <div onClick={()=>downloadReport()} className={"col-xs-12 col-md-4 report_tile " + (reportView==='5' && 'selected')}>Download Children Data</div>
                </div>
            </section>
            {reportView==='4' && <IbadahReport />}
            {reportView==='3' && <ChildrenReport />}
            {reportView==='1' && <Report />}
            {reportView==='2' && <Report4 />}
            {children}
            <Footer01 />
            <CookieConsent />
            <Copyright />
        </div>
    );
}

export default Layout01;