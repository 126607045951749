import { AuthUser, ChartData, ChartDate, Child, RhConfig } from "./Types";


export const defaultChild = (): Child => {
    return {
        childid: "",
        first_name: "",
        last_name: "",
        age: "4",
        gender: "Girl",
        grade: "PREK",
        DaysMissing: 0,
        DaysUpdated: 0,
        fasting_first_time: "no",
        hero: ""
    };
}


export const defaultChartData = (): ChartData => {
    return {
        charity_help: "",
        dhuhr_f: true,
        dhuhr_n: false,
        dhuhr_sa: false,
        dhuhr_sb: false,
        dua_memorized: false,
        dua_read: false,
        fajr_f: true,
        fajr_sb: false,
        fasting: "",
        hadith_memorized: false,
        hadith_read: false,
        help_others: false,
        help_parents: false,
        isha_f: true,
        isha_n: false,
        isha_sa: false,
        isha_sb: false,
        asr_f: true,
        asr_sb: false,
        maghrib_f: true,
        maghrib_n: false,
        maghrib_sa: false,
        parents_comment: "",
        quran_memorized: false,
        quran_read: false,
        tahajjud: false,
        taraweeh: false,
        witr: false,
    };
}


export const defaultChartDate = (): ChartDate => {
    return {
        Data: defaultChartData(),
        DataPresent: false,
        Date: "",
        FutureDate: false,
        Ramadan: 0,
    };
}


export const defaultRhConfig = (): RhConfig => {
    return {
        Masaajid: [],
        Message: "",
        Success: false,
        calendar_year: "",
        hijri: "",
        ramadan_end_date: new Date(0),
        ramadan_start_date: new Date(0),
        version: "",
    }
}


export const defaultAuthUser = (): AuthUser => {
    return {
        AccountLocked: false,
        masjid_id: 0,
        email: "",
        sessionToken: "",
        role:""
    };
}