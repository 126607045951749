import Layout02 from "../layout/Layout02/Layout02";
import TermsAndConditions from "./TermsAndConditions";

const TermsAndConditionsPage: React.FC = (): JSX.Element => {

    return (
        <Layout02>
            
            <TermsAndConditions fullPage={true} height="100%"/>
            
        </Layout02>
    );
}

export default TermsAndConditionsPage;
