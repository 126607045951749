import { useEffect, useState } from "react";
import { ChartDate } from "../../services/Types";

interface Props {
    chartDates: ChartDate[];
}

interface ChartTotals {
    total: number
    charity_help: number;
    dhuhr_f: number;
    dhuhr_n: number;
    dhuhr_sa: number;
    dhuhr_sb: number;
    dua_memorized: number;
    dua_read: number;
    fajr_f: number;
    fajr_sb: number;
    fasting: number;
    hadith_memorized: number;
    hadith_read: number;
    help_others: number;
    help_parents: number;
    isha_f: number;
    isha_n: number;
    isha_sa: number;
    isha_sb: number;
    maghrib_f: number;
    maghrib_n: number;
    maghrib_sa: number;
    asr_f: number;
    asr_sb: number;
    parents_comment: number;
    quran_memorized: number;
    quran_read: number;
    tahajjud: number;
    taraweeh: number;
    witr: number;
};

const defaultChartTotal = (): ChartTotals => {
    return {
        total: 0,
        charity_help: 0,
        dhuhr_f: 0,
        dhuhr_n: 0,
        dhuhr_sa: 0,
        dhuhr_sb: 0,
        dua_memorized: 0,
        dua_read: 0,
        fajr_f: 0,
        fajr_sb: 0,
        fasting: 0,
        hadith_memorized: 0,
        hadith_read: 0,
        help_others: 0,
        help_parents: 0,
        isha_f: 0,
        isha_n: 0,
        isha_sa: 0,
        isha_sb: 0,
        maghrib_f: 0,
        maghrib_n: 0,
        maghrib_sa: 0,
        asr_f: 0,
        asr_sb: 0,
        parents_comment: 0,
        quran_memorized: 0,
        quran_read: 0,
        tahajjud: 0,
        taraweeh: 0,
        witr: 0,
    };
}

const PrintChartTotal: React.FC<Props> = ({ chartDates }): JSX.Element => {

    const [chartTotals, setChartTotals] = useState<ChartTotals>();

    useEffect(() => {
        const cts = defaultChartTotal();
        chartDates.forEach(cd => {
            if (cd.Data.charity_help) cts.charity_help++;
            if (cd.Data.dhuhr_f) cts.dhuhr_f++;
            if (cd.Data.dhuhr_n) cts.dhuhr_n++;
            if (cd.Data.dhuhr_sa) cts.dhuhr_sa++;
            if (cd.Data.dhuhr_sb) cts.dhuhr_sb++;
            if (cd.Data.dua_memorized) cts.dua_memorized++;
            if (cd.Data.dua_read) cts.dua_read++;
            if (cd.Data.fajr_f) cts.fajr_f++;
            if (cd.Data.fajr_sb) cts.fajr_sb++;
            if ("full" === cd.Data.fasting) cts.fasting++; else if ("half" === cd.Data.fasting) cts.fasting += 0.5;
            if (cd.Data.hadith_memorized) cts.hadith_memorized++;
            if (cd.Data.hadith_read) cts.hadith_read++;
            if (cd.Data.help_others) cts.help_others++;
            if (cd.Data.help_parents) cts.help_parents++;
            if (cd.Data.isha_f) cts.isha_f++;
            if (cd.Data.isha_n) cts.isha_n++;
            if (cd.Data.isha_sa) cts.isha_sa++;
            if (cd.Data.isha_sb) cts.isha_sb++;
            if (cd.Data.maghrib_f) cts.maghrib_f++;
            if (cd.Data.maghrib_n) cts.maghrib_n++;
            if (cd.Data.maghrib_sa) cts.maghrib_sa++;
            if (cd.Data.asr_f) cts.asr_f++;
            if (cd.Data.asr_sb) cts.asr_sb++;
            // if (cd.Data.parents_comment) cts.parents_comment++;
            if (cd.Data.quran_memorized) cts.quran_memorized++;
            if (cd.Data.quran_read) cts.quran_read++;
            if (cd.Data.tahajjud) cts.tahajjud++;
            if (cd.Data.taraweeh) cts.taraweeh++;
            if (cd.Data.witr) cts.witr++;
        });

        cts.total = cts.charity_help
            + cts.dhuhr_f
            + cts.dhuhr_n
            + cts.dhuhr_sa
            + cts.dhuhr_sb
            + cts.dua_memorized
            + cts.dua_read
            + cts.fajr_f
            + cts.fajr_sb
            + cts.fasting
            + cts.hadith_memorized
            + cts.hadith_read
            + cts.help_others
            + cts.help_parents
            + cts.isha_f
            + cts.isha_n
            + cts.isha_sa
            + cts.isha_sb
            + cts.maghrib_f
            + cts.maghrib_n
            + cts.maghrib_sa
            + cts.asr_f
            + cts.asr_sb
            + cts.parents_comment
            + cts.quran_memorized
            + cts.quran_read
            + cts.tahajjud
            + cts.taraweeh
            + cts.witr;


        setChartTotals(cts);
    }, []);


    return (
        <tr>
            <td><b>Totals</b></td>
            <td><b>{chartTotals?.fajr_sb}</b></td>
            <td><b>{chartTotals?.fajr_f}</b></td>
            <td><b>{chartTotals?.dhuhr_sb}</b></td>
            <td><b>{chartTotals?.dhuhr_f}</b></td>
            <td><b>{chartTotals?.dhuhr_sa}</b></td>
            <td><b>{chartTotals?.dhuhr_n}</b></td>
            <td><b>{chartTotals?.asr_sb}</b></td>
            <td><b>{chartTotals?.asr_f}</b></td>
            <td><b>{chartTotals?.maghrib_f}</b></td>
            <td><b>{chartTotals?.maghrib_sa}</b></td>
            <td><b>{chartTotals?.maghrib_n}</b></td>
            <td><b>{chartTotals?.isha_sb}</b></td>
            <td><b>{chartTotals?.isha_f}</b></td>
            <td><b>{chartTotals?.isha_sa}</b></td>
            <td><b>{chartTotals?.isha_n}</b></td>
            <td><b>{chartTotals?.taraweeh}</b></td>
            <td><b>{chartTotals?.tahajjud}</b></td>
            <td><b>{chartTotals?.witr}</b></td>
            <td><b>{chartTotals?.fasting}</b></td>
            <td><b>{chartTotals?.charity_help}</b></td>
            <td><b>{chartTotals?.quran_read}</b></td>
            <td><b>{chartTotals?.quran_memorized}</b></td>
            <td><b>{chartTotals?.hadith_read}</b></td>
            <td><b>{chartTotals?.hadith_memorized}</b></td>
            <td><b>{chartTotals?.dua_read}</b></td>
            <td><b>{chartTotals?.dua_memorized}</b></td>
            <td><b>{chartTotals?.help_parents}</b></td>
            <td><b>{chartTotals?.help_others}</b></td>
        </tr>
    );

}

export default PrintChartTotal;