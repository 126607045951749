import { store } from ".";

export type ToastAlertType = "success" | "warn" | "error";

export interface TostAlert {
    alert: ToastAlertType;
    durationMilliseconds: number;
    message: string;
    show: boolean;
}

const createInitialState = (): TostAlert => {
    return {
        alert: "success",
        durationMilliseconds: 0,
        message: "",
        show: false
    }
}

enum TostAlertAction {
    SHOW = "TOST_ALERT_SHOW",
    HIDE = "TOST_ALERT_HIDE"
}

export interface TostAlertActionType {
    type: TostAlertAction;
    payload: TostAlert;
}

export const showTost = (alertType: ToastAlertType, message: string, duration: number) => {
    store.dispatch<TostAlertActionType>({
        type: TostAlertAction.SHOW,
        payload: {
            message: message,
            alert: alertType,
            durationMilliseconds: duration,
            show: true
        }
    });
}


const hideTost = (prevState: TostAlert) => {
    store.dispatch<TostAlertActionType>({
        type: TostAlertAction.HIDE,
        payload: {...prevState, show: false}
    });
}


let tostInterval: NodeJS.Timer | undefined;

export function tostAlertReducer(prevState: TostAlert = createInitialState(), action: TostAlertActionType) {
    let payload = createInitialState();
    switch (action.type) {

        case TostAlertAction.SHOW:
            payload = action.payload;
            if (tostInterval) {
                clearInterval(tostInterval);
            }
            tostInterval = setInterval(() => hideTost(prevState), payload.durationMilliseconds);
            break;
        case TostAlertAction.HIDE:
            payload = {...prevState, show: false};
            if (tostInterval) {
                clearInterval(tostInterval);
            }
            break;
        default:
            payload = prevState;
    }
    return payload;
}
