import { useEffect, useState } from "react";
import { apiGetChildren } from "../../services/ApiServices";
import { Child } from "../../services/Types";
import AddChild from "./AddChild/AddChild";
import ChildComponent from "./ChildComponent/ChildComponent";

const ChildrenComponent: React.FC = (): JSX.Element => {
  const [children, setChildren] = useState<Array<Child>>([]);

  useEffect(() => {
    getAllChildren();
  }, []);

  const getAllChildren = () => {
    apiGetChildren().then((childrenResponse) => {
      if (childrenResponse.Success && childrenResponse.Children) {
        setChildren(childrenResponse.Children);
      } else {
        setChildren([]);
      }
    });
  }

  // TODO: check why sort not reordering name heading in the list of children ui
  const nameSortCompare = (child1: Child, child2: Child): number => {
    if (child1.first_name === undefined  || child2.first_name === undefined) {

      return 0;
    }
    if (child1.first_name > child2.first_name) {
      return 1;
    } else if (child1.first_name < child2.first_name) {
      return -1
    } else {
      return 0;
    }
  }

  return (
    <div className="container mb-5">

      {children.length < 1 && (
        <div>
          You do not have any children added. Please click Add Child button.
        </div>
      )}
      {children
        // .sort(nameSortCompare)
        .map((child, index) => <ChildComponent child={child} key={index} getAllChildren={getAllChildren} />)}
      <AddChild getAllChildren={getAllChildren} />
    </div>
  );
}

export default ChildrenComponent;