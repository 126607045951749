import { useState } from "react";
import { apiAddChild } from "../../../services/ApiServices";
import { trimToLength } from "../../../services/CommonServices";
import { Child, RemoveChildResponse } from "../../../services/Types";
import ChildForm from "../ChildForm";
import { apiRemoveChild } from "../../../services/ApiServices"

interface Props {
    child: Child;
    getAllChildren: Function;
}

const EditChild: React.FC<Props> = ({ child, getAllChildren }): JSX.Element => {
    const [editChild, setEditChild] = useState<Child>(child);


    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditChild({ ...editChild, [event.target.name]: trimToLength(event.target.value, 100) });
    }


    const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setEditChild({ ...editChild, [event.target.name]: event.target.value });
    };


    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked ? "yes" : "no";
        setEditChild({ ...editChild, [event.target.name]: value });
    };

    const onSave = (event: React.MouseEvent<HTMLButtonElement>): void => {
        apiAddChild(editChild).then((response) => {
            console.log("Edit Child response", response);
            if (response.Success) {
                getAllChildren();
            }
        });
    }


    return (
        <div>
            <ChildForm child={editChild}
                editForm={true}
                onChange={onChange}
                onSelectChange={onSelectChange}
                onCheckboxChange={onCheckboxChange}
                onSave={onSave} 
                getAllChildren={getAllChildren}/>
        </div>
    );
}

export default EditChild;