import { useTypedSelector } from "../../store";
import styles from "./Loading.module.scss";

interface Props {
}

const Loading: React.FC<Props> = ({ }): JSX.Element => {

    const loading = useTypedSelector(state => state.loading);

    if (!loading) {
        return <></>
    }

    return (
        <div className={styles.fullScreenTransparent}>
            <div className={styles.fullScreenContent}>
                <div className={`spinner-grow ${styles.loadingSpinner} text-primary`}
                    role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    );
}

export default Loading;

