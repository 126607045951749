import { CSVReport }  from "../../../services/CSVReport";
import { useEffect, useState,useRef } from "react";
import { apiGetIbadahSummary } from "../../../services/ApiServices";
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
ChartJS.register(...registerables);
// import { Chart as ChartJS } from 'chart.js/auto'
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
//     PointElement,
//     LineElement,
//   } from 'chart.js';

// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend
//   );


  export const options = {
    plugins: {
      title: {
        display: true,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

const labels = [
    "fajr_sb",
    "fajr_f",
    "dhuhr_sb",
    "dhuhr_f",
    "dhuhr_sa",
    "dhuhr_n",
    "asr_sb",
    "asr_f",
    "maghrib_f",
    "maghrib_sa",
    "maghrib_n",
    "isha_sb",
    "isha_f",
    "isha_sa",
    "isha_n",
    "taraweeh",
    "tahajjud",
    "witr",
    "fasting",
    "charity_help",
    "quran_read",
    "quran_memorized",
    "hadith_read",
    "hadith_memorized",
    "dua_read",
    "dua_memorized",
    "help_parents",
    "help_others",
];

export const data = {
labels,
datasets: [
    {
        label: 'Count',
        borderColor: 'rgb(255, 99, 132)',
        data: [5,1,10,3,10,6,7,4,5,9]
    }
],
};
const Report4: React.FC = (): JSX.Element => {
    const chartRef = useRef<ChartJS>(null);
    const [masjid, setMasjid] = useState("all");
    const [graphOptions, setGraphOptions] = useState({...data});
    const [graphData, setGraphData] = useState<Array<any>>([]);
    const [reportData, setReportData] = useState<Array<any>>([]);
    const [reportCol] = useState<Array<string>>(
        [
            "Masjid",
            "fajr_sb",
            "fajr_f",
            "dhuhr_sb",
            "dhuhr_f",
            "dhuhr_sa",
            "dhuhr_n",
            "asr_sb",
            "asr_f",
            "maghrib_f",
            "maghrib_sa",
            "maghrib_n",
            "isha_sb",
            "isha_f",
            "isha_sa",
            "isha_n",
            "taraweeh",
            "tahajjud",
            "witr",
            "fasting",
            "charity_help",
            "quran_read",
            "quran_memorized",
            "hadith_read",
            "hadith_memorized",
            "dua_read",
            "dua_memorized",
            "help_parents",
            "help_others",
            "parents_comment",
        ]);
    useEffect(() => {
        getReportData();
        setGraphOptions({...data})
    }, []);
    
    const getReportData = () => {
        let graph:Array<any> = []
        setReportData([]);  
        apiGetIbadahSummary().then((childrenResponse) => {
        if (childrenResponse.Success && childrenResponse.Data) {
            childrenResponse.Data = [...childrenResponse.Data].sort((a,b)=>a.Masjid.charCodeAt(0)-b.Masjid.charCodeAt(0))
            setReportData(childrenResponse.Data);
            [...childrenResponse.Data].map(item=>[...reportCol].map((a,index)=>{
                if(index!==0) graph[index-1] = (graph[index-1]||0) + item[a]
            }))
            let reportTotal:Array<any> = [];
            if(childrenResponse.Data.length>1){
                [...childrenResponse.Data].map(item=>[...reportCol].map((a,index)=>{
                    reportTotal[index] = (reportTotal[index]||0) + item[a]
                }))
            }
            setGraphData(reportTotal)
            if(graph.length>0){
                if(chartRef.current){
                    chartRef.current.data = {
                        labels,
                        datasets: [
                            {
                                label: 'Ibadah Count',
                                data: graph,
                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            },
                        ],
                    };
                    setTimeout(()=>{
                        chartRef.current?.update();
                    },500)
                }
            }
        } else {
            setReportData([]);
        }
      });
    }
    const masjidHandler = (masjidName: string) => {
        setMasjid(masjidName);
        let graph:Array<any> = [];
        [...reportData].filter(item=>masjidName=="all"?true:item.Masjid==masjidName).map(item=>[...reportCol].map((a,index)=>{
            if(index!==0) graph[index-1] = (graph[index-1]||0) + item[a]
        }))
        if(graph.length>0){
            if(chartRef.current){
                chartRef.current.data = {
                    labels,
                    datasets: [
                        {
                            label: 'Ibadah Count',
                            data: graph,
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                    ],
                };
                setTimeout(()=>{
                    chartRef.current?.update();
                },1000)
            }
        }
    }
    return (
        <header className="masthead text-center">
            {/* FAQ Section Heading*/}
            <h2 className="page-section-heading text-center text-uppercase mb-0">Ibadah Summary</h2>
            {/* Icon Divider*/}
            <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                <div className="divider-custom-line"></div>
            </div>
            {
                reportData.length>1 && 
                    <div className="container" style={{marginBottom:"20px"}}>
                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input onChange={()=>masjidHandler('all')} className="btn-check" type="radio" checked={masjid=="all"} name="masjidR" id="masjidR00" value="all" />
                                <label className="btn btn-outline-primary" htmlFor="masjidR00">All</label>
                            {
                                [...reportData].map((item,index)=>{
                                    return <><input onChange={()=>masjidHandler(item["Masjid"])} checked={masjid==item["Masjid"]} className="btn-check" type="radio" name="masjidR" id={"masjidR"+index} value={item["Masjid"]} />
                                    <label className="btn btn-outline-primary" htmlFor={"masjidR"+index}>{item["Masjid"]}</label></>
                                })
                            }
                        </div>
                    </div>
            } 
            <div className="container">
                {/* <Bar data={{datasets: [{data: [20, 10],}],labels: ['a', 'b']}}/> */}
                <Chart type="bar" options={options} data={graphOptions}  ref={chartRef}/>
                {/* {getGraphData()} */}
            </div>
            <div className="container" style={{fontSize:"10px", overflowX:"scroll"}}>

                <table className="table table-bordered border-primary">
                    <thead>
                        <tr>
                            <th className="col" colSpan={2}></th>
                            <th className="col" colSpan={2}>Fajr</th>
                            <th className="col" colSpan={4}>Zuhr</th>
                            <th className="col" colSpan={2}>Asr</th>
                            <th className="col" colSpan={3}>Maghrib</th>
                            <th className="col" colSpan={4}>Isha</th>
                            <th className="col" colSpan={3}>Additional Salah</th>
                            <th className="col" colSpan={1}>Fasting</th>
                            <th className="col" colSpan={1}>Charity</th>
                            <th className="col" colSpan={2}>Quran</th>
                            <th className="col" colSpan={2}>Hadith</th>
                            <th className="col" colSpan={2}>Dua</th>
                            <th className="col" colSpan={2}>Help</th>
                            <th className="col" colSpan={1}></th>
                        </tr>
                        <tr>
                            <td>#</td>
                            <td>Masjid</td>
                            <td>Sunnah Before</td>
                            <td>Fard</td>
                            <td>Sunnah Before</td>
                            <td>Fard</td>
                            <td>Sunnah After</td>
                            <td>Nafeel</td>
                            <td>Sunnah Before</td>
                            <td>Fard</td>
                            <td>Fard</td>
                            <td>Sunnah After</td>
                            <td>Nafeel</td>
                            <td>Sunnah Before</td>
                            <td>Fard</td>
                            <td>Sunnah After</td>
                            <td>Nafeel</td>
                            <td>tahajjud</td>
                            <td>taraweeh</td>
                            <td>witr</td>
                            <td>fasting</td>
                            <td>charity_help</td>
                            <td>quran_read</td>
                            <td>quran_memorized</td>
                            <td>hadith_read</td>
                            <td>hadith_memorized</td>
                            <td>dua_read</td>
                            <td>dua_memorized</td>
                            <td>help_others</td>
                            <td>help_parents</td>
                            <td>parents_comment</td> 
                        </tr>
                    </thead>
                    <tbody>
                        {
                            [...reportData].filter(item=>masjid=="all"?true:item.Masjid==masjid).map((item,index)=>{
                                return(
                                    <tr key={item.Masjid}>
                                        <td key={item.Masjid+'-'+index} scope="col">{index+1}</td>
                                        {
                                            reportCol.map(a=><td key={item.id+'-'+a} scope="col">{item[a]}</td>)
                                        }
                                    </tr>
                                )
                            })
                        }
                        {
                            graphData.length>0 && masjid=="all" &&
                            <tr>
                                <td scope="col" colSpan={2}>Total</td>
                                {
                                    reportCol.map((a,index)=>index!==0 && <td key={'total-'+a} scope="col">{graphData[index]}</td>)
                                }
                            </tr>   
                        }
                    </tbody>
                </table>
            </div>    
            <button type="button" className="btn btn-primary btn-lg" onClick={()=>CSVReport(reportData,"Seggregate by grade",true,reportCol)}>Download Report</button>
        </header>
    );
}

export default Report4;
