import { useEffect, useState } from "react";
import { apiIbadhaChart } from "../../../services/ApiServices";
import { isEmptyObject, isNotBlank, touchNumber } from "../../../services/CommonServices";
import { defaultChartData } from "../../../services/DefaultObjects";
import { ChartDate, Child } from "../../../services/Types";
import EditChild from "./EditChild";
import UpdateIbadha from "./UpdateIbadha";

interface Props {
    child: Child;
    getAllChildren: Function;
}

interface ChartDaysCount {
    DaysMissing: number;
    DaysUpdated: number;
}

const ChildComponent: React.FC<Props> = ({ child, getAllChildren }): JSX.Element => {

    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    
    const [pullChartData, setPullChartData] = useState<boolean>(false);

    const [chartDaysCount, setChartDaysCount] = useState<ChartDaysCount>({
        DaysMissing: touchNumber(child.DaysMissing),
        DaysUpdated: touchNumber(child.DaysUpdated)
    });


    const [chartDates, setChartDates] = useState<Array<ChartDate>>([]);

    useEffect(() => {
        if (pullChartData) {
            getChartDates();
            // console.log("Call chart Data");
        }

        // NOTE: added comments below to disable warning of getChartDates dependency
        // https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pullChartData]);


    const getChartDates = () => {
        if (child && isNotBlank(child.childid) && chartDates.length < 1) {
            // @ts-ignore Already checking from childid isNotBlank
            apiIbadhaChart(child.childid).then(chartStatusResponse => {
                if (chartStatusResponse && chartStatusResponse.Success
                    && chartStatusResponse.Dates) {
                    const datesWithDefaultValues = makeDatesWithDefaultValues(chartStatusResponse.Dates);
                    setChartDates(datesWithDefaultValues);
                }
            });
        }
    };


    const makeDatesWithDefaultValues = (chartDates: ChartDate[]): ChartDate[] => {
        return chartDates.map(chartDate => {
            if (!chartDate.DataPresent || isEmptyObject(chartDate.Data)) {
                chartDate.Data = defaultChartData();
            }
            return chartDate;
        });
    }


    const createRandomName = (name: string): string => {
        return `${name}_${randomNumber}`;
    }

    const modifyChartDaysCount = (num: number) => {
        const modifiedChartDaysCount: ChartDaysCount = {
            DaysMissing: chartDaysCount.DaysMissing - num,
            DaysUpdated: chartDaysCount.DaysUpdated + num
        }
        setChartDaysCount(modifiedChartDaysCount);
    }

    const checkHero = () => {

        const isHerostar = child.hero
        if (!isHerostar) {
            return (
                <div>
                    <i className="rating__star fas fa-star"></i>
                    <i className="rating__star far fa-star"></i>
                    <i className="rating__star far fa-star"></i>
                </div>)

        } else if (isHerostar === "Hero") {
            return (
                <div>
                    <i className="rating__star fas fa-star"></i>
                    <i className="rating__star fas fa-star"></i>
                    <i className="rating__star far fa-star"></i>
                </div>)
        } else if (isHerostar === "Super Hero") {
            return (
                <div>
                    <i className="rating__star fas fa-star"></i>
                    <i className="rating__star fas fa-star"></i>
                    <i className="rating__star fas fa-star"></i>
                </div>);
        };
    }


    return (
        <div className="accordion-collapse" id={createRandomName("accordionExample")}>
            <div className="accordion-item" >
                <h2 className="accordion-header" id="headingOne">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" 
                        onClick={() => setPullChartData(true)}
                        data-bs-target={createRandomName("#collapseOne")} aria-expanded="true" 
                        aria-controls={createRandomName("collapseOne")}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ fontSize: "x-large" }}>
                                {child.first_name} {child.last_name}
                            </div>
                            <div>{chartDaysCount.DaysUpdated} day(s) updated. {chartDaysCount.DaysMissing} day(s) missing {checkHero()}</div>
                        </div>
                    </button>
                </h2>
                <div id={createRandomName("collapseOne")} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent={createRandomName("#accordionExample")}>
                    <div className="accordion-body">
                        <div style={{ textAlign: "right", padding: "20px 0" }}>
                            <a href={`/printChart?childId=${child.childid}`} target="_blank" style={{ fontSize: "large" }}>
                                <i className="fas fa-print"></i> View/Print Ibadah Chart
                            </a>
                        </div>

                        <ul className="nav nav-tabs" id="myTab" role="tablist">

                            <li className="nav-item" role="presentation">
                                <button className="nav-link active"
                                    id={createRandomName("updateIbadah-tab")} data-bs-toggle="tab" data-bs-target={createRandomName("#updateIbadah")} type="button"
                                    role="tab" aria-controls={createRandomName("updateIbadah")} aria-selected="false">
                                    Update Ibadah
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link " id={createRandomName("editChild-tab")} data-bs-toggle="tab"
                                    data-bs-target={createRandomName("#editChild")} type="button" role="tab"
                                    aria-controls={createRandomName("editChild")} aria-selected="true">
                                    Edit Info
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id={createRandomName("updateIbadah")} role="tabpanel" aria-labelledby={createRandomName("updateIbadah-tab")}>
                                <UpdateIbadha
                                    modifyChartDaysCount={modifyChartDaysCount}
                                    child={child}
                                    chartDates={chartDates}
                                    setChartDates={setChartDates} />
                            </div>
                            <div className="tab-pane fade " id={createRandomName("editChild")} role="tabpanel" aria-labelledby={createRandomName("editChild-tab")}>
                                <EditChild child={child} getAllChildren={getAllChildren} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChildComponent;
