import { store } from ".";

export const LoadingTypes = {
    LOADING: 'API_LOADING'
}

export interface LoadingAction {
    type: typeof LoadingTypes.LOADING;
    payload: boolean;
}

export const showLoading = (show: boolean) => {
    store.dispatch({
        type: LoadingTypes.LOADING,
        payload: show
    })
}

export function loadingReducer(prevState: boolean = false, action: LoadingAction) {
    switch (action.type) {
        case LoadingTypes.LOADING:
            return action.payload;
        default:
            return prevState;
    }
}
