const About: React.FC = (): JSX.Element => {
    return (
        <section className="page-section mb-0" id="about">
            <div className="container">
                {/* About Section Heading*/}
                <h2 className="page-section-heading text-center text-uppercase mb-0">About</h2>
                {/* Icon Divider*/}
                <div className="divider-custom">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                    <div className="divider-custom-line"></div>
                </div>
                {/* About Section Content*/}
                <div className="row">
                    <div className="col-lg-6 ms-auto">
                        <p className="lead">The Young Ramadan Heroes program started at Masjid Hamzah in 2017 to recognize young kids who fasted for the fist time and/or for the whole month.</p>
                        <p className="lead">Over the years, as the program grew in popularity among the kids, as well as the parents, we expanded the program to also include other forms of worship (<i>Ibaadaat</i>) to encourage our youth to make the most of the month of Ramadan.</p>
                    </div>
                    <div className="col-lg-6 me-auto">
                        <p className="lead">Towards the end of Ramadan, we conduct a special ceremony at the masjid to recognize kids who have gone above and beyond to complete the recommended <i>Ibaadaat</i> and seek Allah's pleasure.</p>
                        <p className="lead">Based on certain criteria, children are either recognized as "Heroes" or "Super Heroes". They receive a certificate and a goody bag in appreciation of their efforts. Through this program, we hope to inculcate in our kids an attitude of humility, self-sacrifice and, mostly importantly, true worship of Allah (SWT) in a fun, engaging manner.</p>
                    </div>
                </div>
                {/* About Section Button*/}
                {/* <div className="text-center mt-4">
                    <a className="btn btn-xl btn-outline-light" href="https://startbootstrap.com/theme/freelancer/">
                        <i className="fas fa-download me-2"></i>
                        Free Download!
                    </a>
                </div> */}
            </div>
        </section>
    );
}

export default About;