import { combineReducers, Reducer } from 'redux';
import { userReducer } from './AuthUserReducer';
import { loadingReducer } from './LoadingReducer';
import { TostAlert, tostAlertReducer } from './TostAlertReducer';
import { AuthUser, RhConfig } from '../services/Types';
import { rhConfigReducer } from './RhConfigReducer';

export interface IAppState {
    authUser: AuthUser;
    loading: boolean;
    tostAlert: TostAlert;
    rhConfig: RhConfig
}

export const rootReducer: Reducer<IAppState> = combineReducers<IAppState>({
    authUser: userReducer,
    loading: loadingReducer,
    tostAlert: tostAlertReducer,
    rhConfig: rhConfigReducer
} as any);