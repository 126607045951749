import React, { useEffect } from "react"
import './App.css';
import { useTypedDispatch } from "./store";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from "./components/Home";
import AdminHome from "./components/AdminHome";
import { lsGetAuthUser } from "./services/LocalStorageService";
import Loading from "./components/common/Loading";
import PrintChart from "./components/PrintChart/PrintChart";
import PrintChartAdmin from "./components/PrintChart/PrintChartAdmin";
import Tost from "./components/common/Tost";
import { apiRhConfig } from "./services/ApiServices";
import { saveRhConfig } from "./store/RhConfigReducer";
import { showTost } from "./store/TostAlertReducer";
import { AuthUserActions } from "./store/AuthUserReducer";
import TermsAndConditionsPage from "./components/TermsAndConditions/TermsAndConditionsPage";
import Privacy from "./components/Privacy/Privacy";

const App: React.FC = (): JSX.Element => {

  const dispatch = useTypedDispatch();

  // On application startup load previously logged in user and config.
  useEffect(() => {
    // User
    const lsAuthUser = lsGetAuthUser();
    if (lsAuthUser) {
      dispatch({
        type: AuthUserActions.SAVE,
        payload: lsAuthUser
      });
    }
    // Config
    apiRhConfig().then((rhConfig) => {
      if (rhConfig.Success) {
        saveRhConfig(rhConfig)
      } else {
        showTost("error", rhConfig.Message, 3000);
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <Loading />
      <Tost />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<AdminHome />} />
        <Route path="/adminPrintChart" element={<PrintChartAdmin />} />
        <Route path="/printChart" element={<PrintChart />} />
        <Route path="/terms" element={<TermsAndConditionsPage />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
