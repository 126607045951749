const Header01: React.FC = (): JSX.Element => {
    return (
        <header className="masthead bg-primary text-white text-center">
            <div className="container d-flex align-items-center flex-column">
                {/* Masthead Image*/}
                {/* <img className="masthead-avatar mb-5" src="static/assets/img/kids.png" alt="..." /> */}
                <img className="masthead-avatar mb-5" src="static/assets/img/dua.png" alt="..." />
                {/* Masthead Heading*/}
                <h1 className="masthead-heading text-uppercase mb-0">I am a Ramadan Hero</h1>
                {/* Icon Divider*/}
                <div className="divider-custom divider-light">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                    <div className="divider-custom-line"></div>
                </div>
                {/* Masthead Subheading*/}
                <p className="masthead-subheading font-weight-light mb-0">Ibaadah is my Superpower</p>
            </div>
        </header>
    );
}

export default Header01;