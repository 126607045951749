
export interface GenericResponse {
    Message: string;
    Success: boolean;
}

export interface LoginCredsRequest {
    email: string;
    password: string;
    passwordHash: string;
}

export interface LoginCredsResponse extends GenericResponse {
    sessiontoken: string;
    AccountLocked: boolean;
    Masjid: number;
    Role: string;
    AccountUninitialized: boolean;
}


export interface AuthUser {
    email?: string ;
    sessionToken?: string;
    AccountLocked: boolean;
    masjid_id: number;
    role: string;
}

export enum LocalStorageTypes {
    authUser = "authUser"
}

export interface Child {
    childid?: string;
    first_name?: string;
    last_name?: string;
    age?: string;
    grade?: string;
    DaysMissing?: number;
    DaysUpdated?: number;
    fasting_first_time: "yes" | "no";
    hero?: string;
    gender?: string;
}

export interface AddChildResponse extends GenericResponse {
    childid?: string;
}

export interface RemoveChildResponse extends GenericResponse {
    childid?: string;
}

export interface RegisterRequest {
    email: string;
    first_name: string;
    last_name: string;
    phone_number: string;
    password: string;
    passwordHash: string;
    masjid_id: string;
}

export interface RegisterResponse extends GenericResponse {
    Exception: boolean;
}


export interface PasswordResetRequest {
    email: string;
}

export interface ChildrenResponse extends GenericResponse {
    Children: Array<Child>;
}

export interface ChildResponse extends GenericResponse {
    Child?: Child;
}

export interface ChartStatusResponse extends GenericResponse {
    Dates: Array<ChartDate>;
}

export interface ChartDate {
    Data: ChartData;
    DataPresent: boolean;
    Date: "";
    FutureDate: boolean;
    Ramadan: number;
}

export interface ChartData {
    charity_help: string;
    dhuhr_f: boolean;
    dhuhr_n: boolean;
    dhuhr_sa: boolean;
    dhuhr_sb: boolean;
    dua_memorized: boolean;
    dua_read: boolean;
    fajr_f: boolean;
    fajr_sb: boolean;
    fasting: "full" | "half" | "";
    hadith_memorized: boolean;
    hadith_read: boolean;
    help_others: boolean;
    help_parents: boolean;
    isha_f: boolean;
    isha_n: boolean;
    isha_sa: boolean;
    isha_sb: boolean;
    maghrib_f: boolean;
    maghrib_n: boolean;
    maghrib_sa: boolean;
    asr_f: boolean;
    asr_sb: boolean;
    parents_comment: string;
    quran_memorized: boolean;
    quran_read: boolean;
    tahajjud: boolean;
    taraweeh: boolean;
    witr: boolean;
}

export interface ChartDataUpdate extends ChartData {
    childid: string;
    date: string;
}


export interface KeyValue {
    key: string;
    value: string;
}

export interface Masjid {
    address: string;
    contact: string;
    donation_link: string;
    id: number;
    location: string;
    name: string;
}

export interface RhConfig extends GenericResponse {
    Masaajid: Masjid[];
    calendar_year: string;
    hijri: string;
    ramadan_end_date: Date;
    ramadan_start_date: Date;
    version: string;
}


export interface RhSponsor {
    imageUrl: string;
    imageAlt: string;
    link?: string;
    durationMilliseconds: number;
}
