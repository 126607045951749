import { isLoggedIn } from "../services/AuthService";
import { useTypedSelector } from "../store";
import About from "./About";
import Faq from "./Faq";
import ChildrenComponent from "./ChildrenComponent/ChildrenComponent";
import Layout01 from "./layout/Layout01/Layout01";
import AdminLayout from "./layout/AdminLayout";
import Login from "./Login";
import Process from "./Process";
import RegisterModel from "./RegisterModel";
import Sponsor from "./Sponsor";
import CustomMasjidBrand from "./CustomMasjidBrand";

const Home: React.FC = (): JSX.Element => {
    const authUser = useTypedSelector(state => state.authUser);
    return (
        <Layout01>
            {
                !isLoggedIn(authUser) && <Login />
            }
            {
                !isLoggedIn(authUser) && <RegisterModel />
            }
            {
                isLoggedIn(authUser) && <CustomMasjidBrand />
            }
            {
                isLoggedIn(authUser) && <ChildrenComponent />
            }
            <Sponsor />
            <Faq />
            <Process />
            <About />
        </Layout01>
    );
}

export default Home;
