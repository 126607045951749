import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { resourceLimits } from 'worker_threads';
import { apiPostIbadhaChart, apiRhConfig } from '../../services/ApiServices';
import {  isBlank, isEqual, isNotBlank } from '../../services/CommonServices';
import { ChartDate, Child } from '../../services/Types';
import { useTypedSelector } from '../../store';
import styles from './PrintChart.module.scss';
import PrintChartTotal from './PrintChartTotal';
const PrintChartAdmin: React.FC = (): JSX.Element => {

    //const [child, setChild] = useState<Child>();
    const [chartDates, setChartDates] = useState<ChartDate[]>();
    const [searchParams] = useSearchParams();
    const childId = searchParams.get("childId");
    const firstName = searchParams.get("firstName");
    const lastName = searchParams.get("lastName");
    const email = searchParams.get("email")||'';
    const [apiErrorMessage, setApiErrorMessage] = useState<string>("");


    useEffect(() => {
        if (childId) {
            // apiGetChildDetails(childId).then((response) => {
            //     if (response.Success) {
            //         setChild(response.Child)
            //     } else {
            //         setApiErrorMessage(`${apiErrorMessage} (${response.Message})`);
            //     }
            // });

            apiPostIbadhaChart(childId,email).then((response) => {
                if (response.Success) {
                    setChartDates(response.Dates)
                } else {
                    setApiErrorMessage(`${apiErrorMessage} (${response.Message})`);
                }
            });
        }
    }, [childId]);

    const rhConfig=useTypedSelector(state=>state.rhConfig);
    const Calendar_year=rhConfig.calendar_year;
    const Hijri=rhConfig.hijri;
    


    const check = (checked: boolean) => {
        return checked ? <i className="fas fa-check"></i>: <span>&nbsp;</span>;
    }

    const checkFasting = (fasting?: string) => {
        let result = <span>&nbsp;</span>;
        
        if (isEqual(fasting, "half")) {
            result = <i className="fas fa-battery-half"></i>;
        }
        
        if (isEqual(fasting, "full")) {
            result = <i className="fas fa-battery-full"></i>;
        }

        if (isBlank(fasting)) {
            result = <i className="fas fa-battery-empty"></i>;
        }
        return result;
    }
    //check for $ 
    const checkDollar =(dollar?: string) => {
      
      if(dollar)
      {
        dollar="$"+dollar;
        return dollar;
      }return;
    }


    const buildChartRow = (chartDate: ChartDate, index: number) => {
        let gregorianDate = undefined;
        if (chartDate.Date) {
            gregorianDate = new Date(chartDate.Date);
        }


        return (
            <React.Fragment key={index}>
                <tr key={`${index}_data`}>
                    <td rowSpan={2}>
                        <div>
                            {chartDate.Ramadan}
                        </div>
                        <div style={{fontSize: "xx-small"}}>
                            {gregorianDate && `${gregorianDate.getUTCMonth() + 1}/${gregorianDate.getUTCDate()}/${gregorianDate.getUTCFullYear()}`}
                        </div>
                    </td>
                    <td>{check(chartDate.Data.fajr_sb)}</td>
                    <td>{check(chartDate.Data.fajr_f)}</td>
                    <td>{check(chartDate.Data.dhuhr_sb)}</td>
                    <td>{check(chartDate.Data.dhuhr_f)}</td>
                    <td>{check(chartDate.Data.dhuhr_sa)}</td>
                    <td>{check(chartDate.Data.dhuhr_n)}</td>
                    <td>{check(chartDate.Data.asr_sb)}</td>
                    <td>{check(chartDate.Data.asr_f)}</td>
                    <td>{check(chartDate.Data.maghrib_f)}</td>
                    <td>{check(chartDate.Data.maghrib_sa)}</td>
                    <td>{check(chartDate.Data.maghrib_n)}</td>
                    <td>{check(chartDate.Data.isha_sb)}</td>
                    <td>{check(chartDate.Data.isha_f)}</td>
                    <td>{check(chartDate.Data.isha_sa)}</td>
                    <td>{check(chartDate.Data.isha_n)}</td>
                    <td>{check(chartDate.Data.taraweeh)}</td>
                    <td>{check(chartDate.Data.tahajjud)}</td>
                    <td>{check(chartDate.Data.witr)}</td>
                    <td>{chartDate.DataPresent && checkFasting(chartDate.Data.fasting)}</td>
                    <td>{checkDollar(chartDate.Data.charity_help)}</td>
                    <td>{check(chartDate.Data.quran_read)}</td>
                    <td>{check(chartDate.Data.quran_memorized)}</td>
                    <td>{check(chartDate.Data.hadith_read)}</td>
                    <td>{check(chartDate.Data.hadith_memorized)}</td>
                    <td>{check(chartDate.Data.dua_read)}</td>
                    <td>{check(chartDate.Data.dua_memorized)}</td>
                    <td>{check(chartDate.Data.help_parents)}</td>
                    <td>{check(chartDate.Data.help_others)}</td>
                    
                </tr>
                <tr key={`${index}_parent_comment`}>
                    <td colSpan={100}>
                        {
                            chartDate.Data.parents_comment 
                            ? <div style={{textAlign: "left"}}>
                                <strong style={{fontSize: "x-small"}}>Parent Comments: </strong>
                                <span style={{paddingLeft: "20px"}}>{chartDate.Data.parents_comment}</span></div> 
                            : <span>&nbsp;</span>
                        }
                    </td>
                </tr>
            </React.Fragment>
        );
    }


    if (isNotBlank(apiErrorMessage)) {
        return (
            <div>{apiErrorMessage}</div>
        );
    }

    if (!firstName || !chartDates) {
        return <div>Loading...</div>
    }

    return (
        <div>
            <table className={styles.chartTable}>
                <thead>
                    <tr>
                        <th colSpan={100}>{firstName} {lastName}
                        <br></br>
                        Ibadah Chart - {Hijri} H({Calendar_year})
                        </th>
                    </tr>
                    <tr>
                        <th rowSpan={2}>Ramadan</th>
                        <th colSpan={2}>Fajr</th>
                        <th colSpan={4}>Zuhr</th>
                        <th colSpan={2}>Asr</th>
                        <th colSpan={3}>Maghrib</th>
                        <th colSpan={4}>Isha</th>
                        <th colSpan={3}>Additional Salah</th>
                        <th>Fasting</th>
                        <th>Charity</th>
                        <th colSpan={2}>Quran</th>
                        <th colSpan={2}>Hadith</th>
                        <th colSpan={2}>Dua</th>
                        <th colSpan={2}>Help</th>

                    </tr>
                    <tr>
                        <th>Sunnah Before</th>
                        <th>Fard</th>
                        <th>Sunnah Before</th>
                        <th>Fard</th>
                        <th>Sunnah After</th>
                        <th>Nafeel</th>
                        <th>Sunnah Before</th>
                        <th>Fard</th>
                        <th>Fard</th>
                        <th>Sunnah After</th>
                        <th>Nafeel</th>
                        <th>Sunnah Before</th>
                        <th>Fard</th>
                        <th>Sunnah After</th>
                        <th>Nafeel</th>
                        <th>Taraweeh</th>
                        <th>Tahajjud</th>
                        <th>Witr</th>
                        <th>Full/Half</th>
                        <th>Dollars($)</th>
                        <th>Read</th>
                        <th>Memorize</th>
                        <th>Read</th>
                        <th>Memorize</th>
                        <th>Read</th>
                        <th>Memorize</th>
                        <th>Help Parents</th>
                        <th>Help Others</th>
                    </tr>
                </thead>
                <tbody>
                    <PrintChartTotal chartDates={chartDates}/>
                    {chartDates.map((cd, index) => buildChartRow(cd, index))}
                    <PrintChartTotal chartDates={chartDates}/>
                </tbody>
            </table>

        </div>
    );
}

export default PrintChartAdmin;
