export const isEmptyObject = (obj?: Object) => {
    return obj 
    && Object.keys(obj).length === 0
    && Object.getPrototypeOf(obj) === Object.prototype;
}

export const isBlank = (str?: string | null): boolean => {
    return str === undefined || str === null || str.length < 1;
}

export const isNotBlank = (str?: string | null): boolean => {
    return !isBlank(str)
}

export const isEqual = (s1?: string, s2?: string) => {
    return isNotBlank(s1) && isNotBlank(s2) && s1 === s2;
}

export const trimToLength = (str: string | undefined | null, length: number): string => {
    if (!str) return '';
    return str.substring(0, length);
};

export const touchString = (str: string | undefined | null): string => {
    if (!str) return '';
    else return str;
};

export const touchNumber = (num: number | undefined | null): number => {
    if (!num) return 0;
    else return num;
};

export class RandomNumberName {
    _randomNumber: number;
    
    constructor() {
        this._randomNumber = Math.floor(1000 + Math.random() * 9000);
    }

    get randomNumber() {
        return this._randomNumber;
    }
    
    createRandomName = (name: string): string => {
        return `${name}_${this._randomNumber}`;
    }
}