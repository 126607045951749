export const  CSVReport = (JSONData:any[], ReportTitle:string, ShowLabel:boolean, reportCol:Array<string>) => {
  //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  var arrData =
    typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

  var CSV = "";

  //This condition will generate the Label/Header
  if (ShowLabel) {
    var row = "";

    //This loop will extract the label from 1st index of on array
    for (var index in reportCol) {
      //Now convert each value to string and comma-seprated
      row += reportCol[index] + ",";
    }

    row = row.slice(0, -1);

    //append Label row with line break
    CSV += row + "\r\n";
  }

  [...arrData].map(item=>{
      var row = "";  
      reportCol.map(a=>{
          row += '"' + item[a] + '",';
      })
      row.slice(0, row.length - 1);
      CSV += row + "\r\n";
  })
  if (CSV === "") {
    alert("Invalid data");
    return;
  }

  //Generate a file name
  var fileName = "";
  //this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, "_");
  fileName += "_"+new Date().getTime()
  //Initialize file format you want csv or xls
  var uri = "data:text/csv;charset=utf-8," + escape(CSV);

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  //this trick will generate a temp <a /> tag
  var link = document.createElement("a");
  link.href = uri;

  //set the visibility hidden so it will not effect on your web-layout
  //link.style = "visibility:hidden";
  link.download = fileName + ".csv";

  //this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};