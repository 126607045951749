import { useState } from "react";
import { apiAddChild } from "../../../services/ApiServices";
import { trimToLength } from "../../../services/CommonServices";
import { defaultChild } from "../../../services/DefaultObjects";
import { AddChildResponse, Child } from "../../../services/Types";
import ChildForm from "../ChildForm";

interface Props {
  getAllChildren: Function;
}

const AddChild: React.FC<Props> = ({getAllChildren}): JSX.Element => {

  const [addChildResponse, setAddChildResponse] = useState<AddChildResponse>({
    Success: false,
    Message: "",
    childid: ""
  });

  const [addChild, setAddChild] = useState<Child>(defaultChild());

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddChild({ ...addChild, [event.target.name]: trimToLength(event.target.value, 100) });
  }

  const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAddChild({ ...addChild, [event.target.name]: event.target.value });
  };

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked ? "yes" : "no";
    setAddChild({ ...addChild, [event.target.name]: value });
  };

  const onSave = (event: React.MouseEvent<HTMLButtonElement>): void => {
    apiAddChild(addChild).then((response) => {
      setAddChildResponse(response);
      getAllChildren();
    });
  }

  const showForm = (addChildResponse: AddChildResponse) => {
    if (addChildResponse.Success) {
      document.location.reload();
      return (
        <div>
          <div>Child Successfully Added!</div>
         </div>
         
      )
    } else {
      return (
        <ChildForm
          editForm={false}
          child={addChild}
          onChange={onChange}
          onSelectChange={onSelectChange}
          onCheckboxChange={onCheckboxChange} 
          onSave={onSave}
          getAllChildren={getAllChildren} />
      );
    }
  }


  return (
    <div>

      <p style={{ marginTop: "50px" }}>
        <a className="btn btn-primary" href="#!" data-bs-toggle="modal" data-bs-target="#addChildModal">
          Add Child
        </a>
      </p>
    
        

      <div className="portfolio-modal modal fade" id="addChildModal" tabIndex={-1} aria-labelledby="addChildModal" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
            <div className="modal-body text-center pb-5">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    {/* Register Modal - Title*/}
                    <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Add Child</h2>
                    {/* Icon Divider*/}
                    <div className="divider-custom">
                      <div className="divider-custom-line"></div>
                      <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                      <div className="divider-custom-line"></div>
                    </div>

                    {!addChildResponse.Success && addChildResponse.Message.length > 0
                      && <div style={{ color: "red" }}>
                        {addChildResponse.Message}
                      </div>}
                    {showForm(addChildResponse)}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  );
}

export default AddChild;