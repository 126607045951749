import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './RootReducer';
import { useSelector, TypedUseSelectorHook, useDispatch as _useDispatch } from "react-redux"
import { LoadingAction } from './LoadingReducer';
import { TostAlertActionType } from './TostAlertReducer';
import { RhConfigActionType } from './RhConfigReducer';
import { AuthUserActionTypes } from './AuthUserReducer';

export const store = createStore(rootReducer, compose(
  applyMiddleware(thunk),
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()) || compose
));

store.subscribe(() => {
  console.log(store.getState());
});

export type RootActionType = AuthUserActionTypes | LoadingAction | TostAlertActionType | RhConfigActionType;
export type RootState = ReturnType<typeof rootReducer>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useTypedDispatch = () => {
  const dispatch = _useDispatch()
  return (event: RootActionType) => {
    dispatch(event)
  }
}