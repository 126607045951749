import { lsSetAuthUser } from '../services/LocalStorageService';
import { defaultAuthUser } from '../services/DefaultObjects';
import { AuthUser } from '../services/Types';

const INITIAL_STATE: AuthUser = defaultAuthUser();


export const AuthUserActions = {
    SAVE: 'AUTH_USER_SAVE',
    DELETE: 'AUTH_USER_DELETE',
}

interface AuthUserSaveAction {
    type: typeof AuthUserActions.SAVE,
    payload: AuthUser
}

interface AuthUserDeleteAction {
    type: typeof AuthUserActions.DELETE,
    payload: {}
}

export const LOCALSTORAGE_AUTH_USER = "LOCALSTORAGE_AUTH_USER";

export type AuthUserActionTypes = AuthUserSaveAction | AuthUserDeleteAction;



export function userReducer(prevState: AuthUser = INITIAL_STATE, action: AuthUserActionTypes) {
    
    switch (action.type) {
        case AuthUserActions.SAVE:
            const authUser = {
                ...prevState,
                ...action.payload
            }
            lsSetAuthUser(authUser);
            return authUser;

        case AuthUserActions.DELETE  :
            lsSetAuthUser(undefined);
            return {}

        default:
            return prevState;
    }
}
