import { AuthUser, Masjid } from "./Types";
import { isLoggedIn } from "./AuthService";

export const DEFAULT_MASJID: Masjid = {
    address: "665 Tidwell Rd, Alpharetta, GA 30004",
    contact: "board@masjidhamzah.com",
    donation_link: "https://www.masjidhamzah.com/donate/",
    id: 1,
    location: "Alpharetta, GA",
    name: "Hamzah"
};


export const loggedInMasjid = (authUser: AuthUser, masjids: Masjid[]): Masjid | undefined => {
    let masjidId: (number | undefined) = undefined

    if (isLoggedIn(authUser) && authUser.masjid_id && authUser.masjid_id) {
        masjidId = authUser.masjid_id;
    }

    if (masjidId && masjids) {
        let foundMasjids = masjids.filter(m => m.id === masjidId);
        if (foundMasjids.length > 0) {
            return foundMasjids[0];
        }
    } else {
        return DEFAULT_MASJID;
    }
}

export const loggedInDefaultMasjid = (authUser: AuthUser, masjids: Array<Masjid>): Masjid => {
    let resultMasjid: Masjid | undefined = loggedInMasjid(authUser, masjids);

    if (resultMasjid === undefined) {
        resultMasjid = DEFAULT_MASJID;
    }

    return resultMasjid;
}
