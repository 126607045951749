import { CSVReport }  from "../../../services/CSVReport";
import { useEffect, useState, useRef } from "react";
import { apiGetAccountsReport } from "../../../services/ApiServices";
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
ChartJS.register(...registerables);
// import { Chart as ChartJS } from 'chart.js/auto'
// import {
//     Chart as ChartJS,
//     CategoryScale,
//     LinearScale,
//     BarElement,
//     Title,
//     Tooltip,
//     Legend,
//   } from 'chart.js';
// import { Chart } from 'react-chartjs-2';
// ChartJS.register(
// CategoryScale,
// LinearScale,
// BarElement,
// Title,
// Tooltip,
// Legend
// );

export const options = {
responsive: true,
plugins: {
    legend: {
    position: 'top' as const,
    },
    title: {
    display: true,
    text: '',
    },
},
};

const labels = ['Elementary', 'Middle'];

export const data = {
labels,
datasets: [
    {
        label: 'Beginner',
        data: [5,1,0,3],
        backgroundColor: 'rgb(255, 99, 132)',
    },
    {
    label: 'Hero',
    data: [5,1,0,3],
    backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
    label: 'Super Hero',
    data: [2,1,0,0],
    backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
],
};

const Report01: React.FC = (): JSX.Element => {
    const chartRef = useRef<ChartJS>(null);
    const [masjid, setMasjid] = useState("all");
    const [graphOptions, setGraphOptions] = useState({...data});
    const [reportData, setReportData] = useState<Array<any>>([]);
    const [graphData, setGraphData] = useState<Array<any>>([]);
    const [reportSummaryData, setReportSummaryData] = useState<any>({});
    const [graphCol] = useState<Array<string>>(
        [
            'Masjid',  
            'ElementaryChildren', 
            'ElementaryChildrenNonHeroes',
            'ElementaryChildrenHeroes', 
            'ElementaryChildrenSuperHeroes', 
            'MiddleChildren', 
            'MiddleChildrenNonHeroes',
            'MiddleChildrenHeroes', 
            'MiddleChildrenSuperHeroes', 
        ]);
    const [reportCol, setReportCol] = useState<Array<string>>(
        [
            'Masjid',  
            'NumberOfParents',
            'NumberOfChildren',  
            'ChildrenWithNoUpdateIn3Days', 
            'NonHeroes',
            'Heroes', 
            'SuperHeroes',
            'ElementaryChildren', 
            'ElementaryChildrenNonHeroes',
            'ElementaryChildrenHeroes', 
            'ElementaryChildrenSuperHeroes', 
            'MiddleChildren', 
            'MiddleChildrenNonHeroes',
            'MiddleChildrenHeroes', 
            'MiddleChildrenSuperHeroes', 
        ]);
    useEffect(() => {
        getReportData();
    }, []);

    const getReportData = () => {
        setReportData([]);  
        apiGetAccountsReport().then((childrenResponse) => {
        if (childrenResponse.Success && childrenResponse.Data) {
            [...childrenResponse.Data].map((item)=>{
                item['NonHeroes'] = item['NumberOfChildren'] - item['Heroes'] - item['SuperHeroes'];
                item['ElementaryChildrenNonHeroes'] = item['ElementaryChildren'] - item['ElementaryChildrenHeroes'] - item['ElementaryChildrenSuperHeroes'];
                item['MiddleChildrenNonHeroes'] = item['MiddleChildren'] - item['MiddleChildrenHeroes'] - item['MiddleChildrenSuperHeroes'];
                return item;
            })

            setReportData(childrenResponse.Data);
            let graph:Array<any> = [];
            [...childrenResponse.Data].filter(item=>masjid=="all"?true:item.Masjid==masjid).map(item=>[...graphCol].map((a,index)=>{
                graph[index] = (graph[index]||0) + item[a]
            }))
            let reportTotal:Array<any> = [];
            if(childrenResponse.Data.length>1){
                [...childrenResponse.Data].map(item=>[...reportCol].map((a,index)=>{
                    reportTotal[index] = (reportTotal[index]||0) + item[a]
                }))
            }
            setGraphData(reportTotal)
            if(graph.length>0){
                let op : Array<any> = [[],[],[],[]]
                for (let i = 1; i < graph.length; i++) {
                    const element = graph[i];
                    op[i%4].push(element)
                }
                if(chartRef.current){
                    chartRef.current.data = {
                        labels,
                        datasets: [
                            {
                                label: 'Beginner',
                                data: op[2],
                                borderColor: 'rgb(255, 99, 132)',
                            },
                            {
                                label: 'Hero',
                                data: op[3],
                                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                            },
                            {
                                label: 'Super Hero',
                                data: op[0],
                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            },
                        ],
                    }
                    setTimeout(()=>{
                        chartRef.current?.update();
                    },1000)
                }
            }
        } else {
            setReportData([]);
        }
      });
    }
    const masjidHandler = (masjidName: string) => {
        setMasjid(masjidName);
        let graph:Array<any> = [];
        [...reportData].filter(item=>masjidName=="all"?true:item.Masjid==masjidName).map(item=>[...graphCol].map((a,index)=>{
            graph[index] = (graph[index]||0) + item[a]
        }))
        if(graph.length>0){
            let op : Array<any> = [[],[],[],[]]
            for (let i = 1; i < graph.length; i++) {
                const element = graph[i];
                op[i%4].push(element)
            }
            if(chartRef.current){
                chartRef.current.data = {
                    labels,
                    datasets: [
                        {
                            label: 'Beginner',
                            data: op[2],
                            borderColor: 'rgb(255, 99, 132)',
                        },
                        {
                            label: 'Hero',
                            data: op[3],
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        },
                        {
                            label: 'Super Hero',
                            data: op[0],
                            backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        },
                    ],
                }
                setTimeout(()=>{
                    chartRef.current?.update();
                },1000)
            }
        }
    }
    return (
        <header className="masthead text-center">
            {/* FAQ Section Heading*/}
            <h2 className="page-section-heading text-center text-uppercase mb-0">Summary of registration</h2>
            {/* Icon Divider*/}
            <div className="divider-custom">
                <div className="divider-custom-line"></div>
                <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                <div className="divider-custom-line"></div>
            </div>
            {
                reportData.length>1 && 
                    <div className="container" style={{marginBottom:"20px"}}>
                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                <input onChange={()=>masjidHandler('all')} className="btn-check" type="radio" checked={masjid=="all"} name="masjidR1" id="masjidR100" value="all" />
                                <label className="btn btn-outline-primary" htmlFor="masjidR100">All</label>
                            {
                                [...reportData].map((item,index)=>{
                                    return <><input onChange={()=>masjidHandler(item["Masjid"])} checked={masjid==item["Masjid"]} className="btn-check" type="radio" name="masjidR1" id={"masjidR1"+index} value={item["Masjid"]} />
                                    <label className="btn btn-outline-primary" htmlFor={"masjidR1"+index}>{item["Masjid"]}</label></>
                                })
                            }
                        </div>
                    </div>
            }
            <div className="container">
                {/* <Bar style={{width:"80%"}} data={data} /> */}
                <Chart type="bar" options={options} data={graphOptions}  ref={chartRef}/>
            </div>
            <div className="container"  style={{fontSize:"12px"}}>

                <table className="table table-bordered border-primary">
                    <thead>
                        <tr>
                            <th className="col" colSpan={8}>Summary</th>
                            <th colSpan={4}>Elementary</th>
                            <th colSpan={4}>Middle</th>
                        </tr>
                        <tr>
                            <th>#</th>  
                            <th>Masjid</th>  
                            <th>Parents</th>
                            <th>Children</th>  
                            <th>NoUpdateIn3Days</th> 
                            <th>Beginner</th> 
                            <th>Heroes</th> 
                            <th>SuperHeroes</th>
                            <th>Children</th> 
                            <th>Beginner</th> 
                            <th>Heroes</th> 
                            <th>SuperHeroes</th> 
                            <th>Children</th> 
                            <th>Beginner</th> 
                            <th>Heroes</th> 
                            <th>SuperHeroes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reportData.filter(item=>masjid=="all"?true:item.Masjid==masjid).map((item,index)=>{
                                return(
                                    <tr key={item.Masjid}>
                                        <td key={item.Masjid+'-'+index} scope="col">{index+1}</td>
                                        {
                                            reportCol.map(a=><td key={item.Masjid+'-'+a} scope="col">{item[a]}</td>)
                                        }
                                    </tr>
                                )
                            })
                        }
                        {
                            graphData.length>0 && masjid=="all" &&
                            <tr>
                                <td scope="col" colSpan={2}>Total</td>
                                {
                                    reportCol.map((a,index)=>index!==0 && <td key={'total-'+a} scope="col">{graphData[index]}</td>)
                                }
                            </tr>   
                        }
                    </tbody>
                </table>
            </div>  
            <button type="button" className="btn btn-primary btn-lg" onClick={()=>CSVReport(reportData,"Summary of registration",true,reportCol)}>Download Report</button>
        </header>
    );
}

export default Report01;
