import { useEffect, useState } from "react";
import { apiPasswordReset } from "../services/ApiServices";
import { isBlank, isNotBlank } from "../services/CommonServices";
import { GenericResponse, PasswordResetRequest } from "../services/Types";
import { showTost } from "../store/TostAlertReducer";
//const EMAIL_REGEX = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;
const EMAIL_REGEX = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

interface Props {
    resetForgotPasswordStateRef: React.MutableRefObject<() => void>;
}

const ForgotPasswordModel: React.FC<Props> = ({ resetForgotPasswordStateRef }): JSX.Element => {
    const [passwordResetRequest, setPasswordResetRequest] = useState<PasswordResetRequest>({ email: "" });
    const [passwordResetResponse, setPasswordResetResponse] = useState<GenericResponse>({ Success: false, Message: "" });
    const [validForm, setValidForm] = useState<boolean>(false);

    const resetForgotPasswordState = () => {
        setPasswordResetRequest({ email: "" });
        setPasswordResetResponse({ Success: false, Message: "" });
        setValidForm(false);
    }

    useEffect(() => {
        resetForgotPasswordStateRef.current = resetForgotPasswordState;
    }, []);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        const freshPasswordResetRequest = { ...passwordResetRequest, [name]: value }
        setPasswordResetRequest(freshPasswordResetRequest);
        setValidForm(validateForm(freshPasswordResetRequest));
    };

    const validateForm = (passwordResetRequest: PasswordResetRequest): boolean => {
        let result = true;
        result = result && EMAIL_REGEX.test(passwordResetRequest.email);
        return result;
    }

    const onSubmitPassword = () => {
        apiPasswordReset(passwordResetRequest).then((response) => {
            setPasswordResetResponse(response);
            if (response.Success) {
                showTost("success", response.Message, 2000);
            } else {
                showTost("error", response.Message, 2000);
            }
        });
    }

    const buildForgotPasswordForm = () => {
        return <>
            <div className="FormField">
                <div className="form-floating mb-3">
                    <input className="form-control"
                        name="email"
                        type="email"
                        placeholder="name@example.com"
                        value={passwordResetRequest.email}
                        onChange={onChange}
                    />
                    <label htmlFor="email">Email address</label>
                </div>
            </div>
            <button onClick={onSubmitPassword} className="btn btn-primary" disabled={!validForm}>
                Submit
            </button>

        </>
    }

    return (
        <div className="portfolio-modal modal fade" id="resetModal" tabIndex={-1} aria-labelledby="resetModal" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div className="modal-body text-center pb-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    {/* Reset Password Modal - Title*/}
                                    <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Reset Password</h2>
                                    {/* Icon Divider*/}
                                    <div className="divider-custom">
                                        <div className="divider-custom-line"></div>
                                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                        <div className="divider-custom-line"></div>
                                    </div>
                                    {!passwordResetResponse.Success && isNotBlank(passwordResetResponse.Message) && (
                                        <>
                                        <p style={{ color: "red" }}>
                                            {passwordResetResponse.Message}
                                        </p>
                                        {buildForgotPasswordForm()}
                                        </>
                                    )}
                                    {!passwordResetResponse.Success && isBlank(passwordResetResponse.Message)
                                        && buildForgotPasswordForm()
                                    }
                                    {passwordResetResponse.Success && isNotBlank(passwordResetResponse.Message)
                                        && (
                                            <div>
                                                Check your email to reset password.
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPasswordModel;