import CookieConsent from "../CookieConsent";
import Copyright from "../Copyright";
import Footer01 from "../Footer01/Footer01";
import Header01 from "../Header01/Header";
import Navigation from "../Navigation/Navigation";

const Layout01: React.FC = ({ children }): JSX.Element => {
    return (
        <div>
            <Navigation/>
            <Header01 />
            {children}
            <Footer01 />
            <CookieConsent />
            <Copyright />
        </div>
    );
}

export default Layout01;