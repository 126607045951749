import { ReactElement } from "react";
import { isBlank, isNotBlank } from "../services/CommonServices";
import { RhSponsor } from "../services/Types";


const rhSponsors: RhSponsor[] = [
    {
        imageUrl: "https://www.masjidhamzah.com/wp-content/uploads/2016/12/carousel_02.png",
        imageAlt: "Sponsor 01",
        link: "https://www.masjidhamzah.com",
        durationMilliseconds: 4000
    },
    {
        imageUrl: "https://www.masjidhamzah.com/wp-content/uploads/2016/12/carousel_01.png",
        imageAlt: "Sponsor 02",
        link: "https://www.hamzahacademy.com",
        durationMilliseconds: 500
    },
    {
        imageUrl: "static/assets/sponsors/mintbanner.jpg",
        imageAlt: "Mint Fashions",
        link: "http://www.mintfashionshop.com",
        durationMilliseconds: 4000
    },
];


const Sponsor: React.FC = (): JSX.Element => {


    const buildCarousel = (sponsors: RhSponsor[]): ReactElement[] => {
        return sponsors.map((sponsor, index) => {
            return (
                <div key={index}
                    className={`carousel-item ${index === 0 ? 'active' : ''}`}
                    data-bs-interval={sponsor.durationMilliseconds}>
                    {isNotBlank(sponsor.link) && (
                        <a href={sponsor.link} target={"_blank"}>
                            <img src={sponsor.imageUrl} className="d-block w-100" alt={sponsor.imageAlt} />
                        </a>
                    )}
                    {isBlank(sponsor.link) && (
                        <img src={sponsor.imageUrl} className="d-block w-100" alt={sponsor.imageAlt} />
                    )}
                </div>
            );
        });
    }


    return (
        <section className="page-section bg-primary text-white mb-0" id="process">
            <div className="container">
                {/* Sponsor Section Heading*/}
                <h2 className="page-section-heading text-center text-uppercase text-secondary text-white mb-0">Our Sponsors</h2>
                {/* Icon Divider*/}
                <div className="divider-custom divider-light">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                    <div className="divider-custom-line"></div>
                </div>

                {/* 
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-6 mb-5">
                        <div className="portfolio-item mx-auto text-center">
                            <a href="https://www.masjidhamzah.com/" target="_new">
                                <img className="img-fluid" style={{ width: "15rem" }} src="static/assets/img/hic.jpg" alt="Hamzah Islamic Center" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 mb-5">
                        <div className="portfolio-item mx-auto text-center">
                            <a href="https://www.hamzahacademy.com/" target="_new">
                                <img className="img-fluid" style={{ width: "15rem" }} src="static/assets/img/hsa.jpg" alt="Hamzah Science Academy" />
                            </a>
                        </div>
                    </div>
                </div>
                */}

                <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {buildCarousel(rhSponsors)}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </section>
    );
}

export default Sponsor;
