import { Link } from "react-router-dom";
import styles from "./TermsAndConditions.module.scss";
interface Props {
    height?: string;
    fullPage?: boolean;
}

const TermsAndConditions: React.FC<Props> = ({height, fullPage}): JSX.Element => {

    const tcTextClasses = (fullPage: boolean = false): string => {
        let textClasses = "";
        
        if (fullPage) {
            textClasses = styles.tcFullPageText;
        } else {
            textClasses = styles.tcSectionText;
        }

        return textClasses;
    }

    return (
        <div className="mb-4" style={{ textAlign: "left" }}>
            
            {fullPage && <div className="mb-4" style={{fontSize: "40px"}}>Terms &amp; Conditions</div>}
            
            {!fullPage && <label className="mb-2 mt-4">Terms &amp; Conditions</label>}

            <div style={height ? {height}: {}} className={tcTextClasses(fullPage)}>
                <p>By registering on this website, I acknowledge that</p>

                <ul>
                    <li>Participation in this program is voluntary</li>
                    <li>I am a parent who is authorized to register my children for this program</li>
                    <li>I have read the <a href="/privacy">privacy policy</a> and I am aware how this site handles my and my children's information</li>
                    <li>Parents are solely responsible for updating their children's Ibadah chart regularly</li>
                    <li>It's parents' responsibility to ensure integrity and completeness of their children's data</li>
                    <li>I know how to contact the website administrators for help or to report issues</li>
                    <li>I shall indemnify, defend and hold harmless the masajid listed above, their respective officials, directors, employees, members and the website administrators (collectively, the "Indemnitees"), from and against any and all damages, claims, losses, expenses, costs, obligations and liabilities</li>
                </ul>

            </div>
        </div>
    );
}

export default TermsAndConditions;