import jsSHA from "jssha";

async function sha256(message: string) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(message);

    // hash the message
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string                  
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

export const sha512 = (message: string): string => {
    const hashObj = new jsSHA("SHA-512", "TEXT", {numRounds: 1});
    hashObj.update(message);
    return hashObj.getHash("HEX");
}

export { sha256 };
