import { apiRemoveChild } from "../../services/ApiServices";
import { RandomNumberName } from "../../services/CommonServices";
import { createSelectOptions } from "../../services/JsxService";
import { Child, KeyValue } from "../../services/Types";
import Model from "../common/Model";
import styles from "../theme.module.scss";




interface Props {
  child: Child;
  editForm: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onSave: (event: React.MouseEvent<HTMLButtonElement>) => void;
  getAllChildren: Function;
}


const ChildForm: React.FC<Props> = ({ child, editForm, onChange, onSelectChange, onCheckboxChange, onSave, getAllChildren }): JSX.Element => {

  const rnn = new RandomNumberName();

  const createAgeOptions = (selectedAge?: string) => {
    const selectOptions: KeyValue[] = [];
    for (let i = 4; i < 16; i++) {
      selectOptions.push({ key: `${i}`, value: `${i} Years` });
    }

    return createSelectOptions(selectOptions, selectedAge === undefined ? "" : selectedAge);
  };

  const removeChild = () => {
    if (child.childid) {
      apiRemoveChild(child.childid).then((response) => {
        console.log("Child Removed ", response);
        getAllChildren();
        document.location.reload();
      });
    }
  }

  const createGradeOptions = (selectedGrade?: string) => {
    const selectOptions: KeyValue[] = [
      { key: "PREK", value: "Pre-K" },
      { key: "K", value: "K" },
      { key: "1", value: "1st" },
      { key: "2", value: "2nd" },
      { key: "3", value: "3rd" },
      { key: "4", value: "4th" },
      { key: "5", value: "5th" },
      { key: "6", value: "6th" },
      { key: "7", value: "7th" },
      { key: "8", value: "8th" },
    
    ];
    return createSelectOptions(selectOptions, selectedGrade === undefined ? "" : selectedGrade);
  }

  const createGenderOptions = (selectedGender?: string) => {
    const selectOptions: KeyValue[] = [
      { key: "Girl", value: "Girl" },
      { key: "Boy", value: "Boy" },
             
    ];
    return createSelectOptions(selectOptions, selectedGender === undefined ? "" : selectedGender);
  }





  return (
    <div className="FormField">
      <div className="form-floating mb-3">
        <input onChange={onChange} value={child.first_name} className="form-control" id={rnn.createRandomName("first_name")} name="first_name" placeholder="First Name" />
        <label htmlFor={rnn.createRandomName("first_name")}>First Name</label>
      </div>

      <div className="form-floating mb-3">
        <input onChange={onChange} value={child.last_name} className="form-control" id={rnn.createRandomName("last_name")} name="last_name" placeholder="Last Name" />
        <label htmlFor={rnn.createRandomName("last_name")}>Last Name</label>
      </div>

      <div className="form-floating mb-3">
        <select onChange={onSelectChange} value={child.gender} className="form-select" id={rnn.createRandomName("gender")} name="gender" placeholder="gender">
          {createGenderOptions(child.gender)}
        </select>
        <label htmlFor={rnn.createRandomName("gender")}>Gender: </label>
      </div>

      <div className="form-floating mb-3">
        <select onChange={onSelectChange} value={child.age} className="form-select" id={rnn.createRandomName("age")} name="age" placeholder="age">
          {createAgeOptions(child.age)}
        </select>
        <label htmlFor={rnn.createRandomName("age")}>Age: </label>
      </div>

      <div className="form-floating mb-3">
        <select onChange={onSelectChange} value={child.grade} className="form-select" id={rnn.createRandomName("grade")} name="grade" placeholder="grade">
          {createGradeOptions(child.grade)}
        </select>
        <label htmlFor={rnn.createRandomName("grade")}>Grade: </label>
      </div>

      <div className="mb-3" style={{ marginBottom: "50px" }}>
        <label 
          htmlFor={rnn.createRandomName("fasting_first_time")} 
          className={styles.checkbox_container}
          style={{textAlign: "left"}}>
          Fasting First Time?
          <input onChange={onCheckboxChange} checked={child.fasting_first_time === "yes"} className={styles.checkbox_container} id={rnn.createRandomName("fasting_first_time")} name="fasting_first_time" type="checkbox" />
          <span className={styles.checkbox_checkmark}></span>
        </label>
      </div>


      <Model
        modelId={rnn.createRandomName("removeChildModel")}
        title="Removing Child?"
        body={(<p>All information of the selected child including Ibadah chart will be deleted. Are you sure?</p>)}
        noTitle="No"
        noFunction={() => { console.log("No Called") }}
        yesTitle="Yes"
        yesFunction={removeChild}
      />


      <div className="form-floating mt-3">

        <button onClick={onSave} className="btn btn-primary">
          {editForm ? "Update" : "Add Child"}
        </button>
        &nbsp;

        {editForm &&
          <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target={rnn.createRandomName("#removeChildModel")}>
            Remove Child
          </button>
        }

      </div>
    </div>



  );
}

export default ChildForm;