const Process: React.FC = (): JSX.Element => {
    return (
        <section className="page-section bg-primary text-white mb-0" id="process">
            <div className="container">
                {/* Process Section Heading*/}
                <h2 className="page-section-heading text-center text-uppercase text-secondary text-white mb-0">Process</h2>
                {/* Icon Divider*/}
                <div className="divider-custom divider-light">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                    <div className="divider-custom-line"></div>
                </div>
                {/* Process Section Content*/}
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <ul>
                            <li className="lead">Registration is enabled on this website as soon as Ramadan starts.</li>
                            <li className="lead">Communication is sent out through the mailing list and other avenues.</li>
                            <li className="lead">Parents who have never had an account on this website are encouraged to register.
                                <ul><li className="lead">If you've forgotten your password, please reset via the <a href="#login" className="text-white">Log In</a> form.</li></ul>
                            </li>
                            <li className="lead">Once registered, parents will have the ability to add details of their children to their profile.</li>
                            <li className="lead">During Ramadan, once every few days, or preferably everyday, parents are encouraged to login and update their children's Ibaadah records.</li>
                            <ul><li className="lead">It is the responsibility of the parents to keep their children's ibaadah record current.</li></ul>
                            <li className="lead">Parents will have the ability to track their children's Ibaadaat throughout the month.</li>
                            <li className="lead">As the end of Ramadan approaches, we will send out an announcement and share the details of the recognition ceremony.</li>
                        </ul>
                        <p className="font-weight-light">Please see the <a href="#faqs" className="text-white">FAQ</a> section for criteria to qualify for "Super Hero" recognition.</p>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Process;