import CookieConsent from "../CookieConsent";
import Copyright from "../Copyright";
import Footer01 from "../Footer01/Footer01";
import Navigation from "../Navigation/Navigation";

const Layout02: React.FC = ({ children }): JSX.Element => {
    return (
        <div>
            <Navigation />
            <div className="container" style={{ textAlign: "left", marginTop: "130px", marginBottom: "50px" }}>
                {children}
            </div>
            <Footer01 />
            <CookieConsent />
            <Copyright />
        </div>
    );
}

export default Layout02;