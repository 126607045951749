import { Link } from "react-router-dom";

const Copyright: React.FC = (): JSX.Element => {

    const spacer = () => {
        return (
            <span style={{margin: "0 10px"}}>|</span>
        );
    }

    return (
        <div className="copyright py-4 text-center text-white">
            <div className="container">
                <small>
                    <a href="https://www.masjidhamzah.com" target="_new">Masjid Hamzah</a>
                    {spacer()}
                    <Link to={"/terms"}>Terms</Link>
                    {spacer()}
                    <a href="/privacy">Privacy Policy</a>
                </small>
            </div>
        </div>
    );
}

export default Copyright;