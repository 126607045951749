import ForgotPasswordModel from "./ForgotPasswordModel";
import React, { useRef, useState } from "react";
import { LoginCredsRequest, LoginCredsResponse } from "../services/Types";
import { apiLogin } from "../services/ApiServices";
import { sha512 } from "../services/HashUtils";
import { useTypedDispatch } from "../store";
import { AuthUserActions } from "../store/AuthUserReducer";


const Login: React.FC = (): JSX.Element => {
    const dispatch = useTypedDispatch();
    const [loginFailed, setLoginFailed] = useState(false);
    const [loginUninitialized, setLoginUninitialized] = useState(false);
    const resetForgotPasswordState = useRef<() => void >(() => {});

    const [loginCredsRequest, setLoginCredsRequest] = useState<LoginCredsRequest>({
        email: "",
        password: "",
        passwordHash: ""
    });

    const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoginCredsRequest({ ...loginCredsRequest, email: event.target.value })
    }

    const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoginCredsRequest({ ...loginCredsRequest, password: event.target.value })
    }

    const handleLoginCredsResponse = (loginCredsRequest: LoginCredsRequest, loginCredsResponse: LoginCredsResponse) => {
        console.log(loginCredsResponse);
        if (loginCredsResponse.Success
            && !loginCredsResponse.AccountLocked
            && loginCredsResponse.sessiontoken !== undefined
            && loginCredsResponse.sessiontoken.length > 0) {


            dispatch({
                type: AuthUserActions.SAVE,
                payload: {
                    email: loginCredsRequest.email,
                    sessionToken: loginCredsResponse.sessiontoken,
                    AccountLocked: loginCredsResponse.AccountLocked,
                    masjid_id: loginCredsResponse.Masjid,
                    role: loginCredsResponse.Role
                }
            });
            if(loginCredsResponse.Role == "Admin" || loginCredsResponse.Role == "SuperAdmin"){
                window.location.href = '/admin'
            }
        } else if (loginCredsResponse.AccountUninitialized) {
            setLoginUninitialized(true)
        } else {
            setLoginUninitialized(false)
            setLoginFailed(true);
        }
    }

    const onSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        loginCredsRequest.passwordHash = sha512(loginCredsRequest.password);
        console.log("Loggin in", loginCredsRequest);
        const loginCredsResponsePromise = apiLogin(loginCredsRequest);
        loginCredsResponsePromise.then((loginCredsResponse) => handleLoginCredsResponse(loginCredsRequest, loginCredsResponse));
    }

    return (
        <section className="page-section mb-0" id="login">
            <div className="container">
                {/* LogIn Section Heading*/}
                <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Log In</h2>
                {/* Icon Divider*/}
                <div className="divider-custom">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                    <div className="divider-custom-line"></div>
                </div>
                {/* LogIn Section Form*/}
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <form id="loginForm" className="needs-validation">
                            {/* Email address input*/}
                            {loginFailed &&
                                <div style={{ color: "red" }}>Login Failed</div>
                            }
                            {loginUninitialized &&
                                <div style={{ color: "red" }}>Account activation pending. Check Inbox or Spam folders for activation email.</div>
                            }
                            <div className="form-floating mb-3">
                                <input className="form-control" id="email" type="email" placeholder="name@example.com" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required
                                    value={loginCredsRequest.email} onChange={onChangeEmail} />
                                <label htmlFor="email">Email address</label>
                                <div className="invalid-feedback" id="emailRequiredError">An email is required.</div>
                            </div>
                            {/* Password input*/}
                            <div className="form-floating mb-3">
                                <input className="form-control" id="password" type="password" placeholder="password"
                                    //  pattern="^[A-Za-z0-9]{8,}$" 
                                    required
                                    value={loginCredsRequest.password} onChange={onChangePassword} />
                                <label htmlFor="password">Password</label>
                                <div className="invalid-feedback" id="passwordRequiredError">A password is required.</div>
                            </div>
                            {/* Forgot Password*/}
                            <div className="text-end mb-5">
                                <a style={{ fontSize: "1.2rem" }} onClick={() => {
                                    if (resetForgotPasswordState.current) {
                                        resetForgotPasswordState.current();
                                    }
                                }} href="#!" data-bs-toggle="modal" data-bs-target="#resetModal">Forgot Password</a>
                            </div>
                            {/* Submit Button*/}
                            <div className="text-center">
                                <button onClick={onSubmit} className="btn btn-primary btn-xl mb-5" style={{ width: "50%" }} id="submitButton" type="submit">Login & Remember Me</button>
                                <p style={{ fontSize: "1.5rem" }}>Don't have an account? <a href="#!" data-bs-toggle="modal" data-bs-target="#registerModal">Register Now</a></p>
                                <p style={{ fontSize: "0.8rem" }}>This form is protected by Google reCAPTCHA to ensure you're not a bot.</p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ForgotPasswordModel resetForgotPasswordStateRef={resetForgotPasswordState}/>
        </section>
    );
}

export default Login;