import { apiUpdateChart } from "../../../services/ApiServices";
import { isBlank, RandomNumberName, touchString } from "../../../services/CommonServices";
import { ChartDataUpdate, ChartDate, Child } from "../../../services/Types";
import { showTost } from "../../../store/TostAlertReducer";
import styles from "./UpdateIbadha.module.scss";
import { useTypedSelector } from '../../../store';
import { loggedInDefaultMasjid } from '../../../services/MasjidServices';



interface Props {
    child: Child;
    modifyChartDaysCount: (num: number) => void;
    chartDates: Array<ChartDate>;
    setChartDates: (chartDates: Array<ChartDate>) => void;
}

const UpdateIbadha: React.FC<Props> = ({ child, chartDates, setChartDates, modifyChartDaysCount }): JSX.Element => {

    const authUser = useTypedSelector(state => state.authUser);
    const rhConfig = useTypedSelector(state => state.rhConfig);
    const masjid = loggedInDefaultMasjid(authUser, rhConfig.Masaajid);
    const masjidUrl = masjid.donation_link;

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, chartDate: ChartDate) => {
        const index = chartDates.findIndex(cds => cds.Ramadan === chartDate.Ramadan);
        if (index > -1 && index < chartDates.length) {
            const foundChartDate = chartDates[index];
            const modifiedChartDate = { ...foundChartDate, Data: { ...foundChartDate.Data, [event.target.name]: event.target.value } };
            const modifiedChartDates = [...chartDates];
            modifiedChartDates[index] = modifiedChartDate;
            setChartDates(modifiedChartDates);
        }
    }


    const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, chartDate: ChartDate) => {
        // Update state in a an array
        // https://stackoverflow.com/questions/29537299/react-how-to-update-state-item1-in-state-using-setstate
        const index = chartDates.findIndex(cds => cds.Ramadan === chartDate.Ramadan);
        if (index > -1 && index < chartDates.length) {
            const foundChartDate = chartDates[index];
            const modifiedChartDate = { ...foundChartDate, Data: { ...foundChartDate.Data, [event.target.name]: event.target.checked } };
            const modifiedChartDates = [...chartDates];
            modifiedChartDates[index] = modifiedChartDate;
            setChartDates(modifiedChartDates);
        }
    };

    const onFastingRadioChange = (event: React.ChangeEvent<HTMLInputElement>, chartDate: ChartDate) => {
        const index = chartDates.findIndex(cds => cds.Ramadan === chartDate.Ramadan);
        if (index > -1 && index < chartDates.length) {
            const foundChartDate = chartDates[index];
            const modifiedChartDate = { ...foundChartDate, Data: { ...foundChartDate.Data, "fasting": event.target.value } };
            const modifiedChartDates = [...chartDates];
            // @ts-ignore
            modifiedChartDates[index] = modifiedChartDate;
            setChartDates(modifiedChartDates);
        }
    }


    const onSubmit = (ramadan: number) => {
        chartDates
            .filter(cd => cd.Ramadan === ramadan)
            .map(cd => saveChartDate(child, cd));
    }


    const saveChartDate = (child: Child, chartDate: ChartDate) => {
        const chartDataUpdate: ChartDataUpdate = {
            date: chartDate.Date,
            childid: touchString(child.childid),
            ...chartDate.Data
        }

        apiUpdateChart(chartDataUpdate).then((genericResponse) => {
            if (genericResponse.Success) {
                const index = chartDates.findIndex(cds => cds.Ramadan === chartDate.Ramadan);
                const modifiedChartDates = [...chartDates];
                const modifiedChartDate = { ...modifiedChartDates[index], Data: { ...chartDataUpdate }, DataPresent: true };
                modifiedChartDates[index] = modifiedChartDate;
                setChartDates(modifiedChartDates);
                modifyChartDaysCount(1);
                showTost("success", genericResponse.Message, 2000);
            } else {
                if (genericResponse.Message) {
                    showTost("error", genericResponse.Message, 2000);
                } else {
                    showTost("error", "Failed to save", 2000);
                }
            }
        });
    }


    const buildChartData = (chartDate: ChartDate) => {
        const rnn = new RandomNumberName();
        return (
            <div className={styles.chart}>
                {/* Fajr */}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Fajr</div>
                    <div className={styles.chart_inputs}>
                        <div>
                            <label htmlFor={rnn.createRandomName("fajr_sb")} className={styles.checkbox_container}>
                                Sunnah Before
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.fajr_sb}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("fajr_sb")}
                                    name="fajr_sb"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor={rnn.createRandomName("fajr_f")} className={styles.checkbox_container}>
                                Fard
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.fajr_f}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("fajr_f")}
                                    name="fajr_f"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>
                    </div>
                </div>



                {/* Zuhr */}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Zuhr</div>
                    <div className={styles.chart_inputs}>
                        <div>
                            <label htmlFor={rnn.createRandomName("dhuhr_sb")} className={styles.checkbox_container}>
                                Sunnah Before
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.dhuhr_sb}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("dhuhr_sb")}
                                    name="dhuhr_sb"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor={rnn.createRandomName("dhuhr_f")} className={styles.checkbox_container}>
                                Fard
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.dhuhr_f}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("dhuhr_f")}
                                    name="dhuhr_f"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor={rnn.createRandomName("dhuhr_sa")} className={styles.checkbox_container}>
                                Sunnah After
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.dhuhr_sa}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("dhuhr_sa")}
                                    name="dhuhr_sa"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor={rnn.createRandomName("dhuhr_n")} className={styles.checkbox_container}>
                                Nafeel
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.dhuhr_n}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("dhuhr_n")}
                                    name="dhuhr_n"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Asr */}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Asr</div>
                    <div className={styles.chart_inputs}>
                        <div>
                            <label htmlFor={rnn.createRandomName("asr_sb")} className={styles.checkbox_container}>
                                Sunnah Before
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.asr_sb}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("asr_sb")}
                                    name="asr_sb"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor={rnn.createRandomName("asr_f")} className={styles.checkbox_container}>
                                Fard
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.asr_f}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("asr_f")}
                                    name="asr_f"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Maghrib */}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Maghrib</div>
                    <div className={styles.chart_inputs}>
                        <div>
                            <label htmlFor={rnn.createRandomName("maghrib_f")} className={styles.checkbox_container}>
                                Fard
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.maghrib_f}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("maghrib_f")}
                                    name="maghrib_f"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor={rnn.createRandomName("maghrib_sa")} className={styles.checkbox_container}>
                                Sunnah After
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.maghrib_sa}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("maghrib_sa")}
                                    name="maghrib_sa"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>

                        <div>
                            <label htmlFor={rnn.createRandomName("maghrib_n")} className={styles.checkbox_container}>
                                Nafeel
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.maghrib_n}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("maghrib_n")}
                                    name="maghrib_n"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>

                    </div>
                </div>

                {/* Isha */}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Isha</div>
                    <div className={styles.chart_inputs}>
                        <div>
                            <label htmlFor={rnn.createRandomName("isha_sb")} className={styles.checkbox_container}>
                                Sunnah Before
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.isha_sb}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("isha_sb")}
                                    name="isha_sb"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor={rnn.createRandomName("isha_f")} className={styles.checkbox_container}>
                                Fard
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.isha_f}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("isha_f")}
                                    name="isha_f"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor={rnn.createRandomName("isha_sa")} className={styles.checkbox_container}>
                                Sunnah After
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.isha_sa}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("isha_sa")}
                                    name="isha_sa"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>

                        <div>
                            <label htmlFor={rnn.createRandomName("isha_n")} className={styles.checkbox_container}>
                                Nafeel
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.isha_n}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("isha_n")}
                                    name="isha_n"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>

                    </div>
                </div>

                {/* Taraweeh/Witr/Tahajjud */}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Additional Salah</div>
                    <div className={styles.chart_inputs}>
                        <div>
                            <label htmlFor={rnn.createRandomName("taraweeh")} className={styles.checkbox_container}>
                                Taraweeh
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.taraweeh}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("taraweeh")}
                                    name="taraweeh"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>

                        <div>
                            <label htmlFor={rnn.createRandomName("witr")} className={styles.checkbox_container}>
                                Witr
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.witr}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("witr")}
                                    name="witr"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>

                        <div>
                            <label htmlFor={rnn.createRandomName("tahajjud")} className={styles.checkbox_container}>
                                Tahajjud
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.tahajjud}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("tahajjud")}
                                    name="tahajjud"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>

                    </div>
                </div>

                {/* Fasting */}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Fasting</div>
                    <div className={styles.chart_inputs}>
                        <div>
                            <label htmlFor={rnn.createRandomName("fasting_full")} className={styles.radio_container}>
                                Full
                                <input
                                    id={rnn.createRandomName("fasting_full")}
                                    type="radio"
                                    checked={chartDate.Data.fasting === "full"}
                                    name={rnn.createRandomName("fasting")}
                                    value="full"
                                    onChange={(e) => onFastingRadioChange(e, chartDate)} />
                                <span className={styles.radio_checkmark}></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor={rnn.createRandomName("fasting_half")} className={styles.radio_container}>
                                Half
                                <input
                                    id={rnn.createRandomName("fasting_half")}
                                    type="radio"
                                    checked={chartDate.Data.fasting === "half"}
                                    name={rnn.createRandomName("fasting")}
                                    value="half"
                                    onChange={(e) => onFastingRadioChange(e, chartDate)} />
                                <span className={styles.radio_checkmark}></span>
                            </label>
                        </div>
                        <div>
                            <label htmlFor={rnn.createRandomName("fasting_none")} className={styles.radio_container}>
                                None
                                <input
                                    id={rnn.createRandomName("fasting_none")}
                                    type="radio"
                                    checked={isBlank(chartDate.Data.fasting)}
                                    name={rnn.createRandomName("fasting")}
                                    value=""
                                    onChange={(e) => onFastingRadioChange(e, chartDate)} />
                                <span className={styles.radio_checkmark}></span>
                            </label>
                        </div>
                    </div>
                </div>

                {/* Charity or Help ($) */}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Charity or Help ($) <br></br>
                    <a className="btn btn-outline-dark" href={masjidUrl} target="_new"><i className="fas fa-donate me-2"></i>Donate.</a>
                    </div>
                    <div className={styles.chart_inputs}>
                        <input type="number" className="form-control"
                            id={rnn.createRandomName("charity_help")}
                            placeholder=""
                            name="charity_help"
                            value={chartDate.Data.charity_help}
                            onChange={e => onChange(e, chartDate)} />
                    </div>
                </div>


                {/* Quran */}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Quran</div>
                    <div className={styles.chart_inputs}>

                        <div>
                            <label htmlFor={rnn.createRandomName("quran_read")} className={styles.checkbox_container}>
                                Read
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.quran_read}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("quran_read")}
                                    name="quran_read"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>


                        <div>
                            <label htmlFor={rnn.createRandomName("quran_memorized")} className={styles.checkbox_container}>
                                Memorize
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.quran_memorized}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("quran_memorized")}
                                    name="quran_memorized"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>

                    </div>
                </div>

                {/* Hadith*/}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Hadith</div>
                    <div className={styles.chart_inputs}>

                        <div>
                            <label htmlFor={rnn.createRandomName("hadith_read")} className={styles.checkbox_container}>
                                Read
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.hadith_read}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("hadith_read")}
                                    name="hadith_read"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>


                        <div>
                            <label htmlFor={rnn.createRandomName("hadith_memorized")} className={styles.checkbox_container}>
                                Memorize
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.hadith_memorized}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("hadith_memorized")}
                                    name="hadith_memorized"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>

                    </div>
                </div>



                {/* Dua */}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Dua</div>
                    <div className={styles.chart_inputs}>

                        <div>
                            <label htmlFor={rnn.createRandomName("dua_read")} className={styles.checkbox_container}>
                                Read
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.dua_read}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("dua_read")}
                                    name="dua_read"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>


                        <div>
                            <label htmlFor={rnn.createRandomName("dua_memorized")} className={styles.checkbox_container}>
                                Memorize
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.dua_memorized}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("dua_memorized")}
                                    name="dua_memorized"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>

                    </div>
                </div>


                {/* Help */}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Help</div>
                    <div className={styles.chart_inputs}>

                        <div>
                            <label htmlFor={rnn.createRandomName("help_parents")} className={styles.checkbox_container}>
                                Help Parents
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.help_parents}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("help_parents")}
                                    name="help_parents"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>


                        <div>
                            <label htmlFor={rnn.createRandomName("help_others")} className={styles.checkbox_container}>
                                Help Others
                                <input
                                    onChange={(e) => onCheckboxChange(e, chartDate)}
                                    checked={chartDate.Data.help_others}
                                    className={styles.checkbox_container}
                                    id={rnn.createRandomName("help_others")}
                                    name="help_others"
                                    type="checkbox" />
                                <span className={styles.checkbox_checkmark}></span>
                            </label>
                        </div>

                    </div>
                </div>

                {/* Parents Comment */}
                <div className={styles.chart_section}>
                    <div className={styles.chart_label}>Parent's Comment</div>
                    <div className={styles.chart_inputs}>
                        <input type="text" className="form-control"
                            maxLength={200}
                            id={rnn.createRandomName("parents_comment")}
                            placeholder=""
                            name="parents_comment"
                            value={chartDate.Data.parents_comment}
                            onChange={e => onChange(e, chartDate)} />
                    </div>
                </div>

            </div>
        );
    }

    return (
        <div style={{ margin: "30px 0 10px 0" }}>
            <div className="accordion" id="chartAccordion">
                {chartDates.map((chartDate, index) => (
                    <div key={index} className="accordion-item">
                        <h2 className="accordion-header" id={`chartAccordionHeading_${index}`}>
                            <button
                                className={`accordion-button ${chartDate.DataPresent ? styles.accordionButtonSaved : styles.accordionButtonUnsaved}`}
                                type="button" disabled={chartDate.FutureDate}
                                data-bs-toggle="collapse" data-bs-target={`#chartAccordionCollapse_${index}`}
                                aria-expanded="true" aria-controls={`chartAccordionCollapse_${index}`}>
                                {chartDate.Date} - Ramadan {chartDate.Ramadan}
                            </button>
                        </h2>
                        <div id={`chartAccordionCollapse_${index}`} className="accordion-collapse collapse"
                            aria-labelledby={`chartAccordionHeading_${index}`} data-bs-parent="#chartAccordion">
                            <div className="accordion-body">
                                {buildChartData(chartDate)}

                                <div>
                                    <button
                                        onClick={() => onSubmit(chartDate.Ramadan)}
                                        type="button"
                                        className="btn btn-primary mb-3">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default UpdateIbadha;