import {useTypedSelector } from "../store";
import About from "./About";
import Faq from "./Faq";
import ChildrenComponent from "./ChildrenComponent/ChildrenComponent";
import AdminLayout from "./layout/AdminLayout";
import Process from "./Process";
import Sponsor from "./Sponsor";
import React, { useEffect } from "react"
const Home: React.FC = (): JSX.Element => {
    return (
            <AdminLayout>
                <ChildrenComponent />
                <Sponsor />
                <Faq />
                <Process />
                <About />    
            </AdminLayout>
    );
}

export default Home;
