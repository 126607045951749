import { useEffect } from "react";
import Layout02 from "../layout/Layout02/Layout02";


const Privacy: React.FC = (): JSX.Element => {


    useEffect(() => {
        const openModels = document.querySelectorAll('modal');
        if (openModels) {
            console.log(openModels);
        }
    }, []);

    return (
        <Layout02>
            <div className="mb-4" style={{ fontSize: "40px" }}>Privacy Policy</div>
            <div className="mb-4">
                <a href="/static/assets/YRH-Privacy-Policy.pdf" download={true}>
                    Download Privacy Policy
                </a>
            </div>
            <iframe src="/static/assets/YRH-Privacy-Policy.pdf" width="100%" height="800px">

            </iframe>



        </Layout02>
    );
}

export default Privacy;
