import { Link } from "react-router-dom";
import { isLoggedIn } from "../../../services/AuthService";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import { useNavigate } from "react-router";
import { AuthUserActions } from "../../../store/AuthUserReducer";

const Navigation: React.FC = (): JSX.Element => {
    const authUser = useTypedSelector(state => state.authUser);
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const onLogout = () => {
        dispatch({
            type: AuthUserActions.DELETE,
            payload: {}
        });
        navigate("/");
    }

    return(
        <nav className="navbar navbar-expand-lg bg-secondary text-uppercase fixed-top" id="mainNav">
            <div className="container">
                <Link className="navbar-brand" to="/">Young Ramadan Heroes</Link>

                <a className="navbar-brand" href="#page-top"></a>
                <button className="navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item mx-0 mx-lg-1" hidden><a className="nav-link py-3 px-0 px-lg-3 rounded" href="#home">Home</a></li>
                        {
                            !isLoggedIn(authUser) && 
                            <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded" href="/#login">Log In</a></li>
                        }
                        {
                            isLoggedIn(authUser) && 
                            <li className="nav-item mx-0 mx-lg-1">
                                <a onClick={onLogout} className="nav-link py-3 px-0 px-lg-3 rounded" href="/#">{authUser.email} Logout</a>
                            </li>
                        }
                 
{/*                         
                        <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded" href="#about">About</a></li>
                        <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded" href="#process">Process</a></li>
                        <li className="nav-item mx-0 mx-lg-1"><a className="nav-link py-3 px-0 px-lg-3 rounded" href="#faqs">FAQs</a></li> 
*/}

                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navigation;