import { useTypedSelector } from '../store';
import { loggedInDefaultMasjid } from '../services/MasjidServices';

const Faq: React.FC = (): JSX.Element => {
  const authUser = useTypedSelector(state => state.authUser);
  const rhConfig = useTypedSelector(state => state.rhConfig);
  const masjid = loggedInDefaultMasjid(authUser, rhConfig.Masaajid);
  const masjidUrl = masjid.donation_link;


  return (
    <section className="page-section mb-0" id="faqs">
      <div className="container">
        {/* FAQ Section Heading*/}
        <h2 className="page-section-heading text-center text-uppercase mb-0">FAQ's</h2>
        {/* Icon Divider*/}
        <div className="divider-custom">
          <div className="divider-custom-line"></div>
          <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
          <div className="divider-custom-line"></div>
        </div>
        {/* FAQ Section Content*/}
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="accordion accordion-flush" id="faqaccordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="question1">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#answer1" aria-expanded="false" aria-controls="answer1">
                    What's the eligibility criteria to participate?
                  </button>
                </h2>
                <div id="answer1" className="accordion-collapse collapse" aria-labelledby="question1" data-bs-parent="#faqaccordion">
                  <div className="accordion-body">All children that are enrolled in Elementary School or Middle School during current Ramadan are eligible</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="question2">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#answer2" aria-expanded="false" aria-controls="answer2">
                    How do I register my child in the program?
                  </button>
                </h2>
                <div id="answer2" className="accordion-collapse collapse" aria-labelledby="question2" data-bs-parent="#faqaccordion">
                  <div className="accordion-body">Please refer to the “PROCESS” section.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="question3">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#answer3" aria-expanded="false" aria-controls="answer3">
                    Is there a Registration fee to participate in the program?
                  </button>
                </h2>
                <div id="answer3" className="accordion-collapse collapse" aria-labelledby="question3" data-bs-parent="#faqaccordion">
                  <div className="accordion-body">A voluntary donation of $10 per child is encouraged.You can donate here:
                    <div className="col-lg-6 mb-3 mb-lg-0">
                      <p className="lead mb-0"></p>
                      <a className="btn btn-outline-dark" href={masjidUrl} target="_new"><i className="fas fa-donate me-2"></i>Donate.</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="question4">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#answer4" aria-expanded="false" aria-controls="answer4">
                    Can I register more than one child?
                  </button>
                </h2>
                <div id="answer4" className="accordion-collapse collapse" aria-labelledby="question4" data-bs-parent="#faqaccordion">
                  <div className="accordion-body">Yes. You can register multiple children from your family who satisfy eligibility criteria by adding each child to your account profile. Please note, parents need to create only one account.</div></div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="question5">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#answer5" aria-expanded="false" aria-controls="answer5">
                    What’s Ibadah chart?
                  </button>
                </h2>
                <div id="answer5" className="accordion-collapse collapse" aria-labelledby="head5" data-bs-parent="#faqaccordion">
                  <div className="accordion-body">Each registered child will have an Ibadah chart to keep track of his/her daily Ibadah efforts. It consists of all possible categories for 5 daily salah i.e. Fard, Sunnah, Nafl, Witr. Ramadan specific acts of Ibadah are included i.e. Fasting, Taraweeh. Also included are other general acts of Ibadah i.e. Tahajjud, Charity, Reading /Memorization of Quran, Reading/Memorization of Hadith, Reading/Memorization of Dua’s, Helping parents/friends/others.
                    The objective is to introduce these different acts of Ibadah to the children and by keeping track of their daily efforts, encourage them to perform all these forms of Ibadah to the best of their ability during the blessed month of Ramadan when the rewards are multiplied manifolds.</div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="question6">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#answer6" aria-expanded="false" aria-controls="answer6">
                    Whose responsibility is it to update Ibadah chart?
                  </button>
                </h2>
                <div id="answer6" className="accordion-collapse collapse" aria-labelledby="head5" data-bs-parent="#faqaccordion">
                  <div className="accordion-body">It’s parents’ responsibility to update Ibadah chart of their children.</div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="question7">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#answer7" aria-expanded="false" aria-controls="answer7">
                    How often do I need to update Ibadah chart?
                  </button>
                </h2>
                <div id="answer7" className="accordion-collapse collapse" aria-labelledby="head5" data-bs-parent="#faqaccordion">
                  <div className="accordion-body">We encourage you to log into your account and update Ibadah chart of your child on a daily basis or at least once every few days.</div>
                </div>
              </div>


              <div className="accordion-item">
                <h2 className="accordion-header" id="question8">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#answer8" aria-expanded="false" aria-controls="answer8">
                    How much time does it take to update Ibadah chart?
                  </button>
                </h2>
                <div id="answer8" className="accordion-collapse collapse" aria-labelledby="head5" data-bs-parent="#faqaccordion">
                  <div className="accordion-body">It takes less than two minutes.</div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="question9">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#answer9" aria-expanded="false" aria-controls="answer9">
                    Is it possible to keep me logged into my account for the entire month of Ramadan, so I don’t have to enter my login details every single time I need to update Ibadah chart?
                  </button>
                </h2>
                <div id="answer9" className="accordion-collapse collapse" aria-labelledby="head5" data-bs-parent="#faqaccordion">
                  <div className="accordion-body">Yes. As long as you don’t log out, your session will be active.</div>
                </div>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="question10">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#answer10" aria-expanded="false" aria-controls="answer10">
                    What is the criteria for Ramadan Hero and Ramadan Super Hero? How do I know if my child is going to be recognized as Ramadan Hero or Ramadan Super Hero?
                  </button>
                </h2>
                <div id="answer10" className="accordion-collapse collapse" aria-labelledby="head5" data-bs-parent="#faqaccordion">
                  <div className="accordion-body">
                    <p>Please note, our objective is to encourage children to perform as much Ibadah as possible to the best of their ability during the month of Ramadan and reward them with a goody bag and certificate of accomplishment for their efforts. Real reward however is with Allah (swt).
                    </p>
                    <b>Hero: </b>
                    <p>
                      A child needs to perform every single act of Ibadah listed in Ibadah chart at least once during the month of Ramadan to be recognized as a Ramadan Hero. Salah needs to be performed on time – Qaza (missed salah prayed later) doesn’t count. It’s not required for a child to perform all the acts of Ibadah on a single day.
                      When a child fulfills Ramadan Hero criteria, it is indicated with two highlighted stars(<i className="rating__star fas fa-star"></i><i className="rating__star fas fa-star"></i><i className="rating__star far fa-star"></i>) under his/her name.
                    </p>

                    <b>Super Hero: </b>

                    <p>
                      Elementary School Children: A child needs to perform every single act of Ibadah listed in the Ibadah chart at least three times and fast at least five days by 20th of Ramadan to be recognized as Super Hero.
                    </p>
                    <p>
                      Middle School Children: A child needs to perform every single act of Ibadah listed in the Ibadah chart at least five times and fast at least ten days by 20th of Ramadan to be recognized as Super Hero.
                      <br></br>When a child fulfills Ramadan Super Hero criteria, it is indicated with three highlighted stars (<i className="rating__star fas fa-star"></i><i className="rating__star fas fa-star"></i><i className="rating__star fas fa-star"></i>)under his/her name.

                    </p>
                    <table className="table-bordered">
                      <thead>
                        <tr>
                          <th>
                            Category
                          </th>
                          <th>
                            Criteria
                          </th>
                          <th>
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr>
                          <td>
                            Elementary School
                          </td>
                          <td>
                            Perform all acts of Ibadah listed in the Ibadah chart at least once by 20th of Ramadan AND at least 1 day of fasting by 20th day of Ramadan
                          </td>
                          <td>
                            <b> Ramadan Hero </b><br></br>
                            <i className="rating__star fas fa-star"></i>
                            <i className="rating__star fas fa-star"></i>
                            <i className="rating__star far fa-star"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Elementary School
                          </td>
                          <td>
                            Perform all acts of Ibadah listed in Ibadah chart at least <b>3</b> times by 20th of Ramadan AND at least <b>5</b> days of fasting by 20th of Ramadan
                          </td>
                          <td>
                            <b>Ramadan Super Hero</b><br></br>
                            <i className="rating__star fas fa-star"></i>
                            <i className="rating__star fas fa-star"></i>
                            <i className="rating__star fas fa-star"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Middle School
                          </td>
                          <td>
                            Perform all acts of Ibadah listed in the Ibadah chart at least once by 20th of Ramadan AND atleast 1 day of fasting by 20th of Ramadan
                          </td>
                          <td>
                            <b> Ramadan Hero </b><br></br>
                            <i className="rating__star fas fa-star"></i>
                            <i className="rating__star fas fa-star"></i>
                            <i className="rating__star far fa-star"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Middle School
                          </td>
                          <td>
                            Perform all acts of Ibadah listed in the Ibadah chart at least <b>5</b> times by the 20th of Ramadan AND at least 10 days of fasting by 20th of Ramadan
                          </td>
                          <td>
                            <b> Ramadan Super Hero</b><br></br>
                            <i className="rating__star fas fa-star"></i>
                            <i className="rating__star fas fa-star"></i>
                            <i className="rating__star fas fa-star"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faq;